import { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import OrangeButton from '../../components//OrangeButton'
import { useAccount, useBalance } from 'wagmi'
import GreenButton from '../../components/GreenButton'
import { useGetUserKyc } from '../../hooks'
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../../AmplitudeContext'
import { useSource } from '../../hooks/useSource'
import { KYC_CLIENT_ID } from '../../constant'



const KYCButton = ({ component }: { component: string }) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  const [kycHandler, setKycHandler] = useState<any>()

  const { isLoading } = useGetUserKyc()

  const { address } = useAccount()
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const source = useSource()

  const { data: ethBalanceData } = useBalance({
    address: address,
  })
  const balance = ethBalanceData ? ethBalanceData.formatted : '0';
  useEffect(() => {
    if (address) {
      try {
        const blockpass = new window.BlockpassKYCConnect(KYC_CLIENT_ID, {
          refId: address,
        })
        // setKycHandler(blockpass)
        blockpass.on('KYCConnectLoad', () => {
          if (location.pathname === '/portfolio') {
            trackEvent('PortfolioKYCOpen', { referrer: document.referrer });
          }
          trackEvent('MainPageKYCOpen', { referrer: document.referrer });
        })
        blockpass.on("KYCConnectSuccess", () => {
          trackEvent("KYCFormSent", {
            source: source.main,
            wallet_size: Number.parseFloat(balance) ? Number.parseFloat(balance).toString() : 0,
          })
        });
  
        blockpass.startKYCConnect()
      } catch (e) {
        console.error(e, 'BlockpassKYCConnect initialization error')
      }
    }

  }, [address])

  if (component === 'participation') {
    return (
      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        text="Complete KYC"
        mt={5}
        w="200px"
        id="blockpass-kyc-connect"
      />
    )
  }

  if (component === 'homepage') {
    return (
      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        text={'Complete KYC'}
        h={'40px'}
        outlined={true}
        w={'100%'}
        id="blockpass-kyc-connect"
        sx={{
          minWidth: '71px',
          border: '1px solid #63EA71',
          padding: '3px 6px',
          color: '#63EA71',
          backgroundColor: 'transparent',
          '&:hover': {
            border: '1px solid #63EA71',
            backgroundColor: 'transparent',
          },
        }}
      />
    )
  }

  return (
    <GreenButton
      fs={15}
      lh="22.5px"
      fm="Inter"
      fw={400}
      br="24px"
      text={'Complete KYC'}
      h={'40px'}
      outlined={true}
      w={isDown1000 ? '100%' : 'fit-content'}
      id="blockpass-kyc-connect"
      sx={{
        minWidth: '71px',
        border: '1px solid #08493C',
        padding: '3px 16px',
        color: '#63EA71',
        backgroundColor: 'transparent',
        '&:hover': {
          border: '1px solid #27B079',
          backgroundColor: 'transparent',
        },
      }}
    />
  )
}

export default KYCButton
