import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import { AmberCircles } from '../../assets'
import KeepTryingBox from './KeepTryingBox'
import WinAndKYCBox from './WinAndKYCBox'

const RenderWonTicketAndKYCPhase = ({ user }: any) => {
  return <WinAndKYCBox kycStatus={user.kycStatus} />
}

export default RenderWonTicketAndKYCPhase
