import React from 'react'
import {
  Dialog,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { LightCross, ChainChange, GridLines } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useSwitchChain } from 'wagmi'

interface ChainChangeModalProps {
  open: boolean
  handleClose: () => void
  chainId: number
}

export const chainNames = {
  56: 'BSC',
  1: 'ETH',
  42161: 'Arbitrum',
  421614: 'Arbitrum testnet',
  5: 'Goerli',
  43114: 'Avalanche',
  8453: 'Base',
  97: 'BSC Testnet'
}

export const preChainText = {
  56: '0x38',
  1: '0x1',
  42161: '0xA4B1',
  421614: '0x66EEE',
  5: '0x5',
  43114: '0xa86a',
  8453: '0x2105',
  97: '0x61',
}

const ChainChangeModal: React.FC<ChainChangeModalProps> = ({
  open,
  handleClose,
  chainId,
}) => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  const { switchChain } = useSwitchChain()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: '12px',
          maxWidth: '468px',
          width: '100%',
          height: '438px',
          borderRadius: '24px',
          border: '0.5px solid rgba(255, 255, 255, 0.16)',
          backgroundColor: '#fff',
          backgroundImage:
            'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
          position: 'relative',
        },
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '40%',
          backgroundImage: `url(${GridLines})`,
          backgroundRepeat: 'repeat',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          edge="end"
          onClick={handleClose}
          aria-label="close"
          sx={{
            backgroundColor: '#1D1F21',
            height: '40px',
            width: '40px',
            marginRight: '0px',
          }}
        >
          <img src={LightCross} alt="close" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '20px',
          width: isDown600 ? '90%' : '70%',
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        <img
          src={ChainChange}
          alt="Chain Change"
          style={{
            width: '64px',
            height: '64px',
          }}
        />
        <Typography
          sx={{
            paddingTop: '32px',
            paddingBottom: '12px',
            fontSize: '32px',
            fontWeight: 600,
            color: '#000',
            fontFamily: 'Hellix',
          }}
        >
          Switch your wallet
        </Typography>

        <Typography
          sx={{
            color: '#7E8180',
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Inter',
            marginBottom: '40px',
          }}
        >
          {`To view investment status, switch your wallet to
          ${chainNames[chainId as keyof typeof chainNames] || `chain:${chainId}`}
          chain`}
        </Typography>

        <GreenButton
          text={`Switch to 
          ${chainNames[chainId as keyof typeof chainNames] || `chain:${chainId}`} chain`}
          br="24px"
          fs={15}
          fw={400}
          fm="Inter"
          lh="22.5px"
          p="14px 32px"
          h="48px"
          w={'fit-content'}
          mb={7.5}
          onClick={() =>
            switchChain({
              chainId: +preChainText[chainId as keyof typeof preChainText],
            })
          }
        />
      </Box>
    </Dialog>
  )
}

export default ChainChangeModal
