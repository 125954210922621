import { ApeArcadeIcon } from '../../assets'
import ApeArcadeButton from '../../components/ApeArcadeButton'
import { useAnalytics } from '../../AmplitudeContext'

const ButtonArcade = () => {
  const { trackEvent } = useAnalytics();
  const handleArcadeClick = () => {
    trackEvent('ArcadePageOpen', { referrer: document.referrer });
    window.open('https://arcade.apeterminal.io/');
  };

  return (
    <ApeArcadeButton
      text="Ape Arcade"
      br="24px"
      fs={15}
      fw={500}
      fm="Inter"
      lh="22.5px"
      p="16px"
      h="48px"
      w="max-content"
      IconStart={<img
        src={ApeArcadeIcon}
        style={{ marginRight: '7px' }}
        alt="ape arcade icon"
      />}
      onClick={handleArcadeClick}
      sx={{ textTransform: 'none'}}
    />
  )
}

export default ButtonArcade
