import React from 'react'
import { useMediaQuery, Typography, Box, Grid, Skeleton } from '@mui/material'
import { ComingsoonCover, ComingsoonAvatar } from '../../assets'

interface FillerCardProps {
  height: string
}

const FillerCard: React.FC<FillerCardProps> = ({ height }) => {
  const isDown449 = useMediaQuery('(max-width:449px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={'#0F1113'}
      flex={'1'}
      border={'0.5px solid rgba(255, 255, 255, 0.09)'}
      sx={{
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        flex: 1,
        minWidth: isDown449
          ? `calc(${window.innerWidth}px - 60px)`
          : isDown767
          ? '278px'
          : '350px',
        maxWidth: isDown449
          ? `calc(${window.innerWidth}px - 60px)`
          : isDown767
          ? '350px'
          : '390px',
        height: height,
        position: 'relative',
        background:
          'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #141414',
        '&:hover > .launch-soon-hover-bg': {
          opacity: '1 !important',
        },
      }}
    >
      <div
        className="launch-soon-hover-bg"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          borderRadius: '16px',
          transition: 'all 0.3s',
          opacity: 0,
          pointerEvents: 'none',
          userSelect: 'none',
          background:
            'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #141414',
        }}
      />
      <img
        src={ComingsoonCover}
        alt="comingsoon-cover"
        style={{
          width: `calc(100% - 16px)`,
          marginTop: '8px',
          marginRight: '8px',
          marginLeft: '8px',
          objectFit: 'cover',
          borderRadius: '12px',
          height: '209px',
          position: 'relative',
          zIndex: 1,
        }}
        loading="lazy"
      />

      <img
        src={ComingsoonAvatar}
        style={{
          height: '56px',
          width: '56px',
          marginRight: '10px',
          overflow: 'hidden',
          // borderRadius: '16px',
          marginTop: '-40px',
          position: 'relative',
          left: isDown767 ? '16px' : '32px',
          zIndex: 2,
          // border: '1px solid rgba(0, 0, 0, 0.5)',
        }}
        alt="comingsoon-avatar"
        loading="lazy"
      />

      <div style={{ position: 'relative', zIndex: 2 }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap={'16px'}
          px={'32px'}
          mt={'20px'}
          mb={'16px'}
        >
          <Skeleton
            variant="rectangular"
            width={78}
            height={16}
            animation={false}
          />
          <Skeleton
            variant="rectangular"
            width={50}
            height={16}
            animation={false}
          />
        </Box>

        <Box
          gap={'14px'}
          display={'flex'}
          alignItems={'center'}
          flexWrap={'wrap'}
          flexDirection={'column'}
          px={'32px'}
        >
          <SkeletonLoader
            firstLineWidth={78}
            secondLineWidth={50}
            thirdLineWidth={32}
          />

          <SkeletonLoader
            firstLineWidth={90}
            secondLineWidth={0}
            thirdLineWidth={39}
          />

          <SkeletonLoader
            firstLineWidth={isDown449 ? 80 : 138}
            secondLineWidth={0}
            thirdLineWidth={46}
          />

          <SkeletonLoader
            firstLineWidth={83}
            secondLineWidth={0}
            thirdLineWidth={23}
          />
        </Box>
      </div>
    </Box>
  )
}

export default FillerCard

const SkeletonLoader = ({
  firstLineWidth,
  secondLineWidth,
  thirdLineWidth,
}: any) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      height={'24px'}
      flexWrap={'nowrap'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexWrap={'nowrap'}
        gap={'16px'}
      >
        <Skeleton variant="circular" width={16} height={16} animation={false} />
        <Skeleton
          variant="rectangular"
          width={firstLineWidth}
          height={12}
          animation={false}
        />
        <Skeleton
          variant="rectangular"
          width={secondLineWidth}
          height={12}
          animation={false}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width={thirdLineWidth}
        height={12}
        animation={false}
      />
    </Grid>
  )
}
