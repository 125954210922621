import { Box, Skeleton, useMediaQuery } from '@mui/material'

const SkeletonForFeaturedLaunch = () => {
  const isDown1220 = useMediaQuery('(max-width:1220px)')
  const isDown1152 = useMediaQuery('(max-width:1152px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown500 = useMediaQuery('(max-width:500px)')
  const isDown360 = useMediaQuery('(max-width:360px)')

  return (
    <Box
      mb={4}
      width={'100%'}
      maxWidth={'1200px'}
      zIndex={2}
      sx={{
        background: 'linear-gradient(142.67deg, rgba(200, 254, 11, 0.2) 0%, rgba(255, 255, 255, 0.1) 51.04%, rgba(200, 254, 11, 0.4) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          border: 'none',
          background: '#0F1113',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '16px',
          padding: '8px',
          maxHeight: isDown1152 ? '100%' : '400px',
          height: isDown1152 ? '100%' : '400px',
          flexDirection: isDown1152 ? 'column-reverse' : 'row',
          gap: isDown1152 ? '16px' : '0px',
          width: `calc(100% - 1px)`,
          margin: '0.5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingY: isDown1152 ? '8px' : '24px',
            paddingRight: isDown1152 ? '8px' : '32px',
            paddingLeft: isDown1152 ? '8px' : '24px',
            width: isDown1152 ? '100%' : isDown1220 ? '56%' : '44%',
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: isDown1152 ? '8px' : '48px' 
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton 
                variant="rounded" 
                width={isDown500 ? 32 : 48} 
                height={isDown500 ? 32 : 48} 
                sx={{ borderRadius: '12px' }} 
              />
              <Skeleton variant="text" width={150} height={24} sx={{ ml: '12px' }} />
            </Box>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <Skeleton variant="rounded" width={100} height={32} sx={{ borderRadius: '60px' }} />
            </Box>
          </Box>

          <Skeleton 
            variant="text" 
            width="100%" 
            height={isDown1152 ? 24 : 40} 
            sx={{ 
              mb: isDown1152 ? '4px' : '22px',
              mt: isDown1152 ? 0 : '6px' 
            }} 
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: isDown360 ? 'column' : 'row',
              justifyContent: !isDown767 ? 'space-between' : 'flex-start',
              alignItems: 'center',
              padding: isDown767 ? '12px' : '16px 24px',
              gap: isDown500 ? '8px' : '24px',
              border: '0.5px solid rgba(255, 255, 255, 0.16)',
              borderRadius: '12px',
              mb: isDown1152 ? '16px' : '32px',
              minHeight: isDown1152 ? 'auto' : '114px',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Skeleton variant="text" width={80} height={20} sx={{ mb: 1 }} />
              <Skeleton variant="text" width={120} height={30} />
            </Box>
            
            {!isDown360 && (
              <Skeleton 
                variant="rectangular" 
                width={1} 
                height={isDown1152 ? 48 : 64}
                sx={{ mx: isDown767 ? '12px' : '31px' }} 
              />
            )}

            <Box sx={{ width: '100%' }}>
              <Skeleton variant="text" width={120} height={20} sx={{ mb: 1 }} />
              <Skeleton variant="text" width={100} height={30} />
            </Box>
          </Box>

          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              flexDirection: isDown1152 ? 'column' : 'row',
              gap: isDown1152 ? '16px' : '24px',
              width: '100%',
            }}
          >
            <Skeleton 
              variant="rounded" 
              width={isDown1152 ? '100%' : 200} 
              height={48} 
              sx={{ borderRadius: '24px' }} 
            />
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                width: isDown1152 ? '100%' : 'auto',
                justifyContent: isDown1152 ? 'center' : 'flex-start',
              }}
            >
              <Skeleton variant="circular" width={16} height={16} />
              <Skeleton variant="text" width={150} height={32} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: isDown1152 ? '98%' : isDown1220 ? '48%' : '55%',
            minHeight: isDown1152 ? '170px' : '367px',
            maxHeight: isDown1152 ? '170px' : '367px',
            alignSelf: 'center',
            mr: isDown1152 ? '0px' : '8px',
            position: 'relative',
          }}
        >
          <Skeleton 
            variant="rounded" 
            width="100%" 
            height="100%" 
            sx={{ borderRadius: '12px' }} 
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              display: 'flex',
              gap: '4px',
              mb: '8px',
              mr: '4px',
            }}
          >
            <Skeleton variant="rounded" width={80} height={32} sx={{ borderRadius: '60px' }} />
            <Skeleton variant="rounded" width={80} height={32} sx={{ borderRadius: '60px' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SkeletonForFeaturedLaunch
