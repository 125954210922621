import React from 'react'
import Rive, { Layout, Fit } from '@rive-app/react-canvas'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { FundOKX, FundHashkey, FundAnimoca, FundBinance, FundCoinbase, FundSamsungNext, FundSpartan } from '../../assets'

const Funds = ({ launchpadStats }: any) => {
  const isDown999 = useMediaQuery('(max-width:999px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown576 = useMediaQuery('(max-width:576px)')
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  return (
    <Box sx={{
      padding: '44px 0',
      backgroundColor: '#f2f2f2'
    }}>
      <Typography
        className="connect-button__text"
        marginBottom={'64px'}
        fontFamily={'Hellix'}
        fontSize={32}
        fontWeight={600}
        lineHeight={'35.2px'}
        letterSpacing={'-2%'}
        color={"#000"}
        textTransform={'capitalize'}
        textAlign={'center'}
        style={{ transition: 'all 0.3s' }}
        paddingX={isDown767 ? '24px' : '0'}
      >
        Join the Same Pre-Sales as Crypto's Biggest Funds
      </Typography>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={2500}
        centerMode={true}
        className=""
        containerClass="container-with-dots"
        itemClass="fund-carousel-item"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1360
            },
            items: 5,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 960,
              min: 750
            },
            items: 2,
            partialVisibilityGutter: 30
          },
          smallMobile: {
            breakpoint: {
              max: 750,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1360,
              min: 960
            },
            items: 3,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <img src={FundOKX} alt="" />
        <img src={FundHashkey} alt="" />
        <img src={FundAnimoca} alt="" />
        <img src={FundBinance} alt="" />
        <img src={FundCoinbase} alt="" />
        <img src={FundSamsungNext} alt="" />
        <img src={FundSpartan} alt="" />
      </Carousel>
    </Box>
  )
}

export default Funds
