import { api } from '../../api'
import { IIdo } from '../project'

export interface IReferrerResponse {
  ido: IIdo
  project: { name: string; logoUrl: string }
  referralCount: number
  multiplier: number
}

export const referrals = api.injectEndpoints({
  endpoints: build => ({
    fetchUserReferrals: build.query<IReferrerResponse[], void>({
      query: () => `participation/user/referrals`,
    }),
  }),
  overrideExisting: false,
})

export const { useFetchUserReferralsQuery } = referrals
