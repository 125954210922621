import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import { AmberCircles } from '../../assets'
import KeepTryingBox from './KeepTryingBox'

const RenderLost = ({ heading, subText }: any) => {
  return <KeepTryingBox heading={heading} subText={subText} />
}

export default RenderLost
