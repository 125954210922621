import React from 'react'
import { Box, Skeleton, useMediaQuery } from '@mui/material'

const SkeletonForInfoBox = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#0F1113',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        border: '0.5px solid rgba(255, 255, 255, 0.16)',
        width: '100%',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Skeleton
          variant="circular"
          width={30} height={30}
          sx={{ 
            borderRadius: '8px',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          }}
        />
      </Box>
      
      <Box sx={{ mt: isDown1000 ? '12px' : '24px', width: '100%' }}>
        <Skeleton
          variant="text"
          width={80}
          height={20}
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            mb: 1
          }}
        />
        <Skeleton
          variant="text"
          width={120}
          height={isDown1000 ? 28 : 48}
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          }}
        />
      </Box>
    </Box>
  )
}

export default SkeletonForInfoBox
