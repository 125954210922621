import { Box } from '@mui/material'
import React from 'react'

interface FlexProps {
  children: React.ReactNode
  dir?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  jc?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'normal'
    | 'stretch'
    | 'safe'
    | 'unsafe'
    | 'inherit'
    | 'initial'
    | 'unset'
    | undefined
  ai?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'normal'
    | 'safe'
    | 'unsafe'
    | 'inherit'
    | 'initial'
    | 'unset'
    | undefined
  wrap?:
    | 'nowrap'
    | 'wrap'
    | 'wrap-reverse'
    | 'inherit'
    | 'initial'
    | 'unset'
    | undefined
  h?: string | number
  w?: string | number
  bg?: string
  br?: string | number
  fullWidth?: boolean
  mb?: string | number
  p?: string | number
  onClick?: () => void
}

const Flex = ({
  children,
  dir,
  jc,
  ai,
  wrap,
  h,
  w,
  bg,
  br,
  fullWidth,
  p,
  mb,
  onClick,

  ...props
}: FlexProps) => (
  <Box
    display="flex"
    flexDirection={dir || 'row'}
    justifyContent={jc || 'flex-start'}
    alignItems={ai || 'center'}
    flexWrap={wrap || 'nowrap'}
    height={h || 'auto'}
    width={fullWidth ? '100%' : w || 'auto'}
    bgcolor={bg}
    borderRadius={br}
    mb={mb}
    p={p}
    onClick={onClick}
    {...props}
  >
    {children}
  </Box>
)

export default Flex
