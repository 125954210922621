import { Button, Tooltip, Typography } from '@mui/material'

const TransparentButton = ({
  mt = 0,
  text,
  Icon,
  mb = 0,
  h,
  w,
  fs,
  fw,
  lh,
  br,
  onClick,
  sx,
  disabled = false,
  border,
  fm,
  bgColor,
  id = 'BTN',
  toolTipText,
  tooltipPlacement,
}: {
  mt?: number
  mb?: number
  text?: string
  Icon?: any
  h?: string
  w?: string
  fs?: number | string
  fw?: number
  lh?: string
  br?: string
  sx?: any
  onClick?: () => void
  disabled?: boolean
  border?: string
  fm?: string
  bgColor?: string
  id?: string
  toolTipText?: string
  tooltipPlacement?: any
}) => {
  return (
    <Tooltip
      title={toolTipText ? toolTipText : ''}
      placement={tooltipPlacement ? tooltipPlacement : 'bottom'}
    >
      <Button
        sx={{
          mt: mt,
          mb: mb,
          height: h,
          width: w ? w : '100%',
          borderRadius: br,
          border: border ? border : 'none',
          fontFamily: fm ? fm : 'Hellix',
          bgcolor: bgColor ? bgColor : 'transparent',

          ...sx,

          '&:hover': {
            backgroundColor: bgColor ? bgColor : 'rgba(255,255,255,.07)',
          },

          '&:disabled': {
            background: 'transparent',
            border: 'none',
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
        }}
        variant="contained"
        color="primary"
        onClick={e => {
          e.stopPropagation()
          onClick && onClick()
        }}
        disabled={disabled}
        id={id}
      >
        {Icon && (
          <img
            src={Icon}
            alt="icon"
            height={'12px'}
            width={'14px'}
            style={{ marginRight: '10px' }}
          />
        )}
        {text && (
          <Typography
            fontSize={fs ? fs : 15}
            fontWeight={fw ? fw : 400}
            lineHeight={lh ? lh : '22.5px'}
            color="#fff"
            fontFamily={fm ? fm : 'Inter'}
            textTransform={'none'}
          >
            {text}
          </Typography>
        )}
      </Button>
    </Tooltip>
  )
}

export default TransparentButton
