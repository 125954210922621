import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import ChanceOfWinningBox from './ChanceOfWinningBox'
import RenderContainer from './RenderContainer'
import RenderSolanaAddress from './RenderSolanaAddress'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'
import _ from 'lodash';

const RenderParticipationPhase = ({
  score,
  totalParticipants,
  totalAssetsConnected,
  winningChances,
  idoData,
  multiplierData,
  _userParticipation,
}: any) => {
  const [solanaAddressUpdated, setSolanaAddressUpdated] = useState(false)

  // DoJo Protocol - todo set this IDO's claimNetwork to Solana
  const isSolanaIdo = idoData._id === '667165f45403b3d9bccee36d' || idoData.claimNetwork === "Solana";

  const [
    updateSolanaAddressInParticipation,
    {
      data: solanaAddressInParticipationData,
      isSuccess: isSolanaAddressInParticipationSuccess,
      error: solanaAddressInParticipationError,
    },
  ] = useUpdateSolanaAddressInParticipationMutation()

  useEffect(() => {
    if (solanaAddressInParticipationData) {
      setSolanaAddressUpdated(true)
    }
  }, [solanaAddressInParticipationData])

  const renderParticipationPhase = () => {
    if (
      isSolanaIdo &&
      !solanaAddressUpdated &&
      _userParticipation &&
      !_userParticipation?.solanaAddress
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderSolanaAddress
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              userParticipation={_userParticipation}
              updateSolanaAddressInParticipation={
                updateSolanaAddressInParticipation
              }
            />
          }
          type="registered"
        />
      )
    } 

    return (
      <RenderContainer
        idoData={idoData}
        score={score}
        totalParticipants={totalParticipants}
        totalAssetsConnected={totalAssetsConnected}
        multiplierData={multiplierData}
        solanaAddress={solanaAddressInParticipationData?.solanaAddress || _userParticipation?.solanaAddress}
        sideComponent={
          <ChanceOfWinningBox
            winningChances={winningChances}
            ticketPrice={
              idoData.amountToRaise === -1
                ? -1
                : idoData.amountToRaise / idoData.totalInvestors
            }
          />
        }
        type="registered"
      />
    )
  }
  return <>{renderParticipationPhase()}</>
}

export default RenderParticipationPhase
