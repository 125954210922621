import { useAppSelector } from "../../hooks"
import React, { createContext, useContext, useEffect, ReactNode } from 'react'
import { BaseSocket } from "../../sockets/BaseSocket"

const SocketContext = createContext<BaseSocket | null>(null)

export const useSocket = () => {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider')
  }
  return context
}

interface SocketProviderProps {
  children: ReactNode
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const user = useAppSelector(state => state.user)
  const accessToken = user.mainAppToken || user.accessTokenGoogle

  useEffect(() => {
    const currentToken = BaseSocket.getToken();
    if (currentToken && accessToken && currentToken !== accessToken) {
      BaseSocket.getInstance().reconnect(accessToken);
    } else if (accessToken) {
      BaseSocket.getInstance().reconnect(accessToken);
    } else {
      BaseSocket.getInstance().reconnect();
    }
  }, [accessToken]);

  return (
    <SocketContext.Provider value={BaseSocket.getInstance()}>
      {children}
    </SocketContext.Provider>
  )
}
