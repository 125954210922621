import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { useVerifyGoogleTokenMutation } from '../services/modules/users'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import {
  setAccessTokenGoogle,
  setAddressLinkEmail,
  setEmail,
  setGoogleToken,
  setIsLoggedInWithEmail,
  setLoginMethod,
  setNameGoogleToken,
  setSignature,
  setWalletAddress,
} from '../redux/slices/userSlice'
import { useCallback, useRef, useState } from 'react'

export const useSignIn = () => {
  const dispatch = useAppDispatch()
  const { close } = useWeb3Modal()
  const [toastOpen, setToastOpen] = useState(false)
  const [verifyGoogleToken] = useVerifyGoogleTokenMutation()
  const [googleInfo, setGoogleInfo] = useState<{
    email: string
    access_token: string
    addressVerify?: string
  } | null>(null)
  const [addressVerify, setAddressVerify] = useState<string | undefined>(
    undefined,
  )
  const user = useAppSelector(state => state.user)
  const modalCloseRef = useRef<(() => void) | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fetchGoogleProfile = async (access_token: string) => {
    try {
      const response = await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: { Authorization: `Bearer ${access_token}` },
        },
      )
      return await response.json()
    } catch (error) {
      console.error('Failed to fetch Google profile:', error)
      return null
    }
  }

  const handleGoogleLoginSuccess = useCallback(
    async (tokenResponse: TokenResponse) => {
      setLoading(true)
      try {
        const { access_token } = tokenResponse
        const profile = await fetchGoogleProfile(access_token)
        const { email, name } = profile
        modalCloseRef.current?.()
        dispatch(setGoogleToken(access_token))
        dispatch(setEmail(email))
        dispatch(setNameGoogleToken(name))

        if (email && name) {
          const verifyResult = await verifyGoogleToken({
            email,
            googleToken: access_token,
            fullName: name,
          }).unwrap()
          // await openWeb3Modal()
          const { user: userData } = verifyResult
          if (verifyResult) {
            if (userData.address) {
              dispatch(setIsLoggedInWithEmail(false))
              setGoogleInfo({
                email,
                access_token,
                addressVerify: userData.address,
              })
              dispatch(setAddressLinkEmail(userData.address))
              setAddressVerify(userData.address)
            } else {
              if (user.loginMethod === 'metamask') {
                dispatch(setSignature(''))
                dispatch(setWalletAddress(''))
                dispatch(setAccessTokenGoogle(''))
                close()
              }
              dispatch(setIsLoggedInWithEmail(true))
              setGoogleInfo({ email, access_token })
            }
          } else {
            if (user.loginMethod === 'metamask') {
              dispatch(setSignature(''))
              dispatch(setWalletAddress(''))
              dispatch(setAccessTokenGoogle(''))
              close()
            }
            dispatch(setIsLoggedInWithEmail(true))
            setGoogleInfo({ email, access_token })
          }
        } else {
          console.error('Email or name is missing from profile:', profile)
          dispatch(setIsLoggedInWithEmail(false))
        }
      } catch (error) {
        console.error('Login Error:', error)
        dispatch(setIsLoggedInWithEmail(false))
      } finally {
        setLoading(false)
      }
    },
    [close, dispatch, user.loginMethod, verifyGoogleToken],
  )

  const onSuccess = useCallback(
    (tokenResponse: TokenResponse) => {
      dispatch(setLoginMethod('google'))
      handleGoogleLoginSuccess(tokenResponse)
    },
    [dispatch, handleGoogleLoginSuccess],
  )

  const onError = useCallback(() => {
    console.error('Login Failed!')
    dispatch(setIsLoggedInWithEmail(false))
  }, [dispatch])

  const openModal = () => {
    setToastOpen(true)
  }

  const closeModal = () => {
    setToastOpen(false)
  }

  console.log({
    user,
  })

  const handleSigInWithGoogle = useGoogleLogin({
    onSuccess,
    onError,
    flow: 'implicit',
  })

  const closeModalSignInGG = (close: () => void) => {
    modalCloseRef.current = close
  }

  return {
    handleSigInWithGoogle,
    googleInfo,
    addressVerify,
    loading,
    toastOpen,
    errorMessage,
    setErrorMessage,
    setToastOpen,
    openModal,
    closeModal,
    closeModalSignInGG,
  }
}
