import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Dialog, Box, Typography, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LightCross, GreenGradientArrow } from '../../assets'
import { getEpoch } from '../../utils'
import GreenButton from '../GreenButton'

type INotifyItemProps = {
  title?: string
  message?: string | React.ReactNode
  link?: string
  open: boolean
  handleClose: () => void
}

const defaultNotificationTitle = 'Notice'
const defaultNotificationMessage = '(no message)'

export const insertSeparator = (_array: any[], _separator: any = "") => {
  return _.flatMap(_array, (value, index, array) =>
    array.length - 1 !== index ? [value, _separator] : value
  );
};

const NotificationItem = ({
  open,
  message,
  title,
  link,
  handleClose,
}: INotifyItemProps) => {
  const navigate = useNavigate();
  return (
    <Dialog
      hideBackdrop={true}
      disableScrollLock
      disableEnforceFocus
      style={{ pointerEvents: 'none' }}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          margin: '8px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          paddingX: '16px',
          paddingY: '10px',
          maxWidth: '320px',
          width: '100%',
          borderRadius: '24px',
          border: '0.5px solid rgba(255, 255, 255, 0.16)',
          backgroundColor: '#0F1113',
          backgroundImage:
            'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
          pointerEvents: 'auto',
        },
      }}
      sx={{}}
      // #todo: using more documented way
      className="bottom-right-dialog"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
          marginTop: '4px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '17px',
            fontWeight: 500,
            lineHeight: '27.2px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: '#FFFFFF',
          }}
        >
          {title || defaultNotificationTitle}
        </Typography>
        <IconButton
          edge="end"
          onClick={handleClose}
          aria-label="close"
          sx={{
            backgroundColor: '#1D1F21',
            height: '32px',
            width: '32px',
            marginRight: '0px',
            transform: 'translateX(6px)',
          }}
        >
          <img src={LightCross} alt="close" height={14} width={14} />
        </IconButton>
      </Box>

      <Box
        sx={{
          // minHeight: '32px'
          marginBottom: link ? '8px' : '0px',
          color: '#FFFFFF',
          fontFamily: 'Inter',
        }}
      >
        {insertSeparator(String(message).split("\n"), <br />) || defaultNotificationMessage}
      </Box>
      {link && (
        <Box display={'flex'} justifyContent={'flex-end'}>
          <GreenButton
            fs={15}
            lh="22.5px"
            fm="Inter"
            fw={400}
            br="24px"
            p="6px 12px"
            Icon={() => <img src={GreenGradientArrow} alt="forward icon" />}
            text={'Open'}
            disabled={false}
            mt={3}
            onClick={() => {
              navigate(link);
              handleClose();
            }}
            outlined={true}
            w="auto"
            h="auto"
            border={'1px solid #08493C'}
            sx={{
              background: '#000000 !important',
              border: '1px solid #08493C !important',
              marginTop: '4px',
            }}
          />
        </Box>
      )}
    </Dialog>
  )
}

interface INotificationParams {
  id?: string
  title: string
  message: string
  link?: string
  timeoutSecs?: number
}

// currently it is not possible to show multiple notifications
// a combination with react-hot-toast can be used for that purpose
const NotificationSystemC = () => {
  const [notificationParams, setNotificationParams] = useState<
    INotificationParams | undefined
  >(undefined)

  useEffect(() => {
    // #todo: using decent Redux state instead of this temporary solution :clown:
    // @ts-ignore
    window['setAppNotification'] = (
      notificationParams: INotificationParams,
    ) => {
      const { title, message, link, timeoutSecs } = notificationParams
      if (!title && !message) return
      const unixTime = getEpoch()
      const id = _.uniqueId(`${unixTime}_`)
      setNotificationParams({
        title,
        message,
        link,
        id,
      })
      // auto close the notification
      if (timeoutSecs && timeoutSecs > 0) {
        setTimeout(() => {
          setNotificationParams((current?: INotificationParams) => {
            const currentId = _.get(current, 'id')
            if (currentId !== id) return current
            return undefined
          })
        }, timeoutSecs * 1000)
      }
    }
  }, [setNotificationParams])

  return (
    <>
      {!!notificationParams && (
        <NotificationItem
          open={!!notificationParams}
          handleClose={() => {
            setNotificationParams(undefined)
          }}
          title={_.get(notificationParams, 'title')}
          message={_.get(notificationParams, 'message')}
          link={_.get(notificationParams, 'link')}
        />
      )}
    </>
  )
}

export const NotificationSystem = React.memo(NotificationSystemC)
