import React, { useEffect } from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import { Logo } from '../../assets'
import {
  EventData,
  IDataEventIDO,
  IDataEventBroadCast,
  TypeNotiEvent,
} from '../../sockets/socket'

interface ToastMessageProps {
  data: EventData | null
}

const isDataEventRegistration = (
  data: IDataEventBroadCast | IDataEventIDO,
): data is IDataEventIDO => {
  return (data as IDataEventIDO).projectId !== undefined
}

const toastStyle = {
  display: 'flex',
  padding: '12px',
  gap: '12px',
  borderRadius: '8px',
  backgroundColor: '#fff',
  width: '380px',
  maxHeight: '162ppx',
  justifyContent: 'flex-start',
  marginBottom: '8px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  position: 'relative',
}

const logoBoxStyle = {
  border: '0.5px solid rgba(0,0,0,0.2)',
  borderRadius: '12px',
  width: 56,
  height: 56,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  marginRight: '16px',
}

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '250px',
}

const closeIconStyle = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  padding: '0',
  cursor: 'pointer',
}

const ToastMessage: React.FC<ToastMessageProps> = ({ data }) => {
  // const dispatch = useDispatch()

  const handleClickToast = (id: string | undefined) => {
    // dispatch(setToastExpandItem(id));

    if (data.url) {
      window.open(data.url, '_blank')
      return
    }

    if (!data.projectId || !data.routeName) return

    window.location.href = window.location.origin + `/project/${data.projectId}`
  }

  const showToast = () => {
    if (!data) return

    toast.remove('1') // FIXME: need fix the root cause

    toast.custom(t => {
      // let expandId = store.getState().app.toastExpandedItem;
      return (
        <Box
          sx={{
            ...toastStyle,
            cursor: 'pointer',
          }}
        >
          {isDataEventRegistration(data) && data.projectLogo ? (
            <img
              style={{
                width: 56,
                height: 56,
                borderRadius: '12px',
                objectFit: 'contain',
                border: '0.5px solid #1D1F21',
              }}
              src={data.projectLogo}
              alt="logo"
            />
          ) : (
            <Box sx={logoBoxStyle}>
              <img
                src={Logo}
                alt="logo"
                onClick={() => handleClickToast(t.id)}
                style={{ width: 40, height: 40, objectFit: 'contain' }}
              />
            </Box>
          )}
          <Box sx={contentStyle} onClick={() => handleClickToast(t.id)}>
            {isDataEventRegistration(data) ? (
              <>
                {data.type === TypeNotiEvent.REGISTRATION && (
                  <SaleRegistrationContent data={data} />
                )}
                {data.type === TypeNotiEvent.CONTRIBUTION && (
                  <ContributionContent data={data} />
                )}
                {data.time && (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '13px',
                      color: '#767676',
                      marginTop: '6px',
                    }}
                  >
                    {moment(data.time).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    maxWidth: '100%',
                    overflow: data.isTroll ? '' : 'hidden',
                    display: '-webkit-box',
                    wordBreak: 'break-word',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: '6px',
                    textOverflow: data.isTroll ? '' : 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: data.isTroll ? '' : 2,
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#767676',
                    overflow: data.isTroll ? '' : 'hidden',
                    display: '-webkit-box',
                    lineHeight: '18px',
                    wordBreak: 'break-word',
                    whiteSpace: 'break-spaces',
                    textOverflow: data.isTroll ? '' : 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: data.isTroll ? '' : 2,
                  }}
                >
                  {data.content}
                </Typography>
                {data.time && (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '13px',
                      color: '#767676',
                      marginTop: '6px',
                    }}
                  >
                    {moment(data.time).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                )}
              </>
            )}
          </Box>
          <IconButton onClick={() => toast.remove(t.id)} sx={closeIconStyle}>
            <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} />
          </IconButton>
        </Box>
      )
    })
  }

  useEffect(() => {
    if (data) {
      showToast() // Show toast whenever data changes
    }
  }, [data])

  return (
    <Toaster
      toastOptions={{
        duration: 5000,
        style: {
          inset: 'unset',
          width: '435px',
          height: '162px',
          bottom: '0px',
          right: '0px',
        },
      }}
      position="bottom-right"
      reverseOrder
      containerStyle={{
        marginBottom: '16px',
        marginRight: '16px',
        left:'unset',
        top:'unset',
        bottom:'0px',
        right:'0px',
        pointerEvents: 'unset',
        width:'100%',
        minWidth:'15px',
      }}
    />
  )
}

export default ToastMessage

const ContributionContent = ({ data }: any) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 700,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        Contributions Round Starting
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        {(() => {
          const now = moment()
          const investmentStartDate = moment(data?.investmentStartDate)

          if (investmentStartDate.isAfter(now)) {
            const duration = moment.duration(investmentStartDate.diff(now))
            const days = Math.floor(duration.asDays())
            const hours = duration.hours()
            const minutes = duration.minutes()
            const seconds = duration.seconds()

            return (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#767676',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  <b style={{ color: '#000' }}>{data?.projectName}</b>{' '}
                  contributions start in {days > 0 ? `${days}d` : ''} {hours}h{' '}
                  {minutes}m {seconds}s. You registered and won a spot on this
                  sale. Don’t forget to contribute.
                </Typography>
              </Box>
            )
          } else {
            return (
              <Typography
                sx={{
                  fontWeight: 400,
                  color: '#767676',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                }}
              >
                <b style={{ color: '#000' }}>{data?.projectName}</b>{' '}
                contributions start now. You registered and won a spot on this
                sale. Don’t forget to contribute.
              </Typography>
            )
          }
        })()}
      </Typography>
    </>
  )
}

const SaleRegistrationContent = ({ data }: any) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 700,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        Sales Registration Starting
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: '#000000',
          fontFamily: 'Inter',
          fontSize: '14px',
          marginBottom: '8px',
        }}
      >
        {(() => {
          const startRegistration = moment(data?.startRegistration)
          const endRegistration = moment(data?.endRegistration)

          if (endRegistration.isAfter(startRegistration)) {
            const duration = moment.duration(
              endRegistration.diff(startRegistration),
            )
            const days = Math.floor(duration.asDays())
            const hours = duration.hours()
            const minutes = duration.minutes()
            const seconds = duration.seconds()

            return (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#767676',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  <b style={{ color: '#000' }}>{data?.projectName}</b> sales
                  registration opt in is upcoming in{' '}
                  {days > 0 ? `${days}d` : ''} {hours}h {minutes}m {seconds}s.
                  Don’t miss out!
                </Typography>
              </Box>
            )
          } else {
            return (
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#767676',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  <b style={{ color: '#000' }}>{data?.projectName}</b> sales
                  registration start now . Don’t miss out!
                </Typography>
              </Box>
            )
          }
        })()}
      </Typography>
    </>
  )
}
