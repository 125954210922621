import {
  setAffiliateStatus,
  setRefferCode,
} from '../../../redux/slices/userSlice'
import { api } from '../../api'

export interface IReferrer {
  _id: string
  code: string
  affiliateStatus: AffiliateStatus
}

export enum AffiliateStatus {
  NOT_APPLIED = 'not-applied',
  IN_REVIEW = 'in-review',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export const affiliate = api.injectEndpoints({
  endpoints: build => ({
    apply: build.mutation<IReferrer, undefined>({
      query: () => ({
        url: `referrer/affiliate/apply`,
        method: 'POST',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(setAffiliateStatus(data.affiliateStatus))
          dispatch(setRefferCode(data.code))
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching post!'))
          console.error(err, 'could not fetch token from backend')
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useApplyMutation } = affiliate
