import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { StyledHeadTableCell, StyledBodyTableCell } from './CompletedSales'

const SkeletonForCompletedSales = () => {
  const isDown767 = useMediaQuery('(max-width:767px)')

  return (
    <Box>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <Typography
          component="div"
          fontWeight={600}
          fontSize={isDown767 ? 32 : 56}
          lineHeight={isDown767 ? '32px' : '61.5px'}
          letterSpacing={'0px'}
          textAlign={'left'}
          fontFamily={'Hellix'}
          color="#FFFFFF"
          mb={1}
        >
          Completed Sales
        </Typography>
        <Typography
          component="div"
          fontFamily={'Inter'}
          color="#7E8180"
          fontSize={isDown767 ? 17 : 18}
          lineHeight={'27px'}
          fontWeight={400}
          pb={'24px'}
        >
          Previous sales that were hosted on our platform.
        </Typography>

        <Box
          width={'100%'}
          maxWidth={`${window.innerWidth - 40}px`}
          display={'flex'}
          flexDirection={'column'}
        >
          <TableContainer
            component={Paper}
            sx={{
              height: '100%',
              borderRadius: '16px',
              border: '0.5px solid #1D1F21',
              boxShadow: 'none',
              backgroundImage: 'none',
              flexShrink: 1,
              overflowX: 'auto',
              width: '100%',
              '&::-webkit-scrollbar': {
                display: 'flex',
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#313335',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#0F1113',
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                  <StyledHeadTableCell>Total Raise</StyledHeadTableCell>
                  <StyledHeadTableCell>All Time High</StyledHeadTableCell>
                  <StyledHeadTableCell>Type</StyledHeadTableCell>
                  <StyledHeadTableCell>Hosted By</StyledHeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: '#0F1113' }}>
                {[...Array(7)].map((_, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      [`&:last-child td`]: {
                        borderBottom: '0px solid transparent',
                      },
                    }}
                  >
                    <StyledBodyTableCell
                      sx={{
                        minWidth: isDown767 ? '220px' : '250px',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Skeleton
                          variant="rounded"
                          width={40}
                          height={40}
                          sx={{ borderRadius: 2 }}
                        />
                        <Box ml={'12px'}>
                          <Skeleton variant="text" width={120} height={22} />
                          <Skeleton variant="text" width={80} height={16} />
                        </Box>
                      </Box>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      sx={{
                        minWidth: isDown767 ? '200px' : '250px',
                      }}
                    >
                      <Skeleton variant="text" width={100} height={24} />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      <Skeleton variant="text" width={80} height={24} />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      <Skeleton variant="text" width={90} height={24} />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      <Skeleton variant="text" width={120} height={24} />
                    </StyledBodyTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {/* Show More Button Skeleton */}
      <Box mt={2} textAlign="center">
        <Skeleton
          variant="rounded"
          width="100%"
          height={48}
          sx={{ borderRadius: '24px' }}
        />
      </Box>
    </Box>
  )
}

export default SkeletonForCompletedSales
