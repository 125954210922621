import { Box, Link, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import {
  replaceUnderscoresWithSpaces,
  getReplacedCdnEndpoint,
} from '../../utils'

const boxStyle = {
  border: 'none',
  background:
    'radial-gradient(25.58% 55.62% at 24.42% 114.58%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%), #fff',
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'hidden',
  borderRadius: '16px',
  padding: '16px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  maxHeight: `calc(100% - 2px)`,
  height: '100%',
  flexDirection: 'row',
  gap: '32px',
  width: `calc(100% - 2px)`,
  margin: '1px',
}

const RenderContainer = ({
  idoData,
  score,
  totalParticipants,
  totalAssetsConnected,
  multiplierData,
  sideComponent,
  type,
  solanaAddress,
}: {
  idoData: any
  score: number
  totalParticipants: number
  totalAssetsConnected: number
  multiplierData: number
  sideComponent?: any
  type?: string
  solanaAddress?: string
}) => {
  const isSolanaIdo =
    idoData._id === '667165f45403b3d9bccee36d' ||
    idoData.claimNetwork === 'Solana'
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  if (!isDown1000) {
    return (
      <Box sx={{ ...boxStyle }}>
        <Box
          flex={1}
          display={'flex'}
          flexDirection={'column'}
          pl={isDown1000 ? 0 : '16px'}
          pb={isDown1000 ? 0 : '16px'}
          justifyContent={'space-between'}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: isDown1000 ? '16px' : '86px',
              mt: isDown1000 ? '0px' : '16px',
            }}
          >
            <img
              src={getReplacedCdnEndpoint(idoData.project.logoUrl)}
              style={{
                height: '48px',
                width: '48px',
              }}
              alt="featured launch logo"
            />
            <Typography
              fontWeight={400}
              fontSize={18}
              lineHeight={'27px'}
              fontFamily={'Inter'}
              ml={'16px'}
              color={'#000'}
            >
              {idoData.project.name}
            </Typography>
          </Box>
          {type === 'processing' ? (
            <Box>
              <StyledText>
                We’re processing all <br /> addresses now!
              </StyledText>
              <StyledSubText>
                This takes a few hours as we make sure the selection process is
                careful & transparent. Check the{' '}
                <Link
                  //href={() => navigate(`/project/${ido.project.name}`)}

                  rel="noreferrer"
                  color={'#FFB152'}
                  href={`/project/${idoData.project._id},
                  }`}
                >
                  IDO page
                </Link>{' '}
                for exact timelines.
              </StyledSubText>
            </Box>
          ) : type === 'won' ? (
            <Box>
              <StyledText>
                Congratulations, you've <br /> won a chance in the IDO.
              </StyledText>
              <StyledSubText>
                You’re one of the winners, lock in your{!isDown1000 && <br />}{' '}
                place by being the first to invest.
              </StyledSubText>
            </Box>
          ) : type === 'ended' ? (
            <Box>
              <StyledText>The investment phase has concluded</StyledText>
            </Box>
          ) : (
            <>
              {isSolanaIdo && !solanaAddress ? (
                <Box>
                  <StyledText>Almost there</StyledText>
                  <StyledSubText>
                    Connect to your Solana wallet and sign a message to complete
                    registration.
                  </StyledSubText>
                </Box>
              ) : (
                <Box>
                  <StyledText>
                    Congratulations, you've <br /> registered to the IDO
                  </StyledText>
                  <StyledSubText>
                    Your current score is{' '}
                    <b
                      style={{
                        color: '#000000',
                      }}
                    >
                      {score ? Number(score).toFixed(2) : 0} points
                    </b>
                    . Increase it to {!isDown1000 && <br />} win more tickets
                    and earn higher allocations.
                  </StyledSubText>
                </Box>
              )}
            </>
          )}
          <StatsBox
            score={score}
            totalParticipants={totalParticipants}
            totalAssetsConnected={totalAssetsConnected}
            referralMultiplier={multiplierData}
            solanaAddress={solanaAddress}
          />
        </Box>
        <Box
          sx={{
            // backgroundColor: '#000000',
            flex: 1,
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {sideComponent}
        </Box>
      </Box>
    )
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'20px'} width={'100%'}>
      <Box
        sx={{
          backgroundColor: '#000000',
          flex: 1,
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {sideComponent}
      </Box>
      <Box
        zIndex={2}
        sx={{
          borderRadius: '16px',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          maxWidth: '1200px',
          width: `100%`,
          marginBottom: '100px',
          background:
            'linear-gradient(142.67deg, rgba(200, 254, 11, 0.2) 0%, rgba(255, 255, 255, 0.1) 51.04%, rgba(200, 254, 11, 0.4) 100%)',
        }}
      >
        <Box sx={{ ...boxStyle }}>
          <Box
            flex={1}
            display={'flex'}
            flexDirection={'column'}
            pl={isDown1000 ? 0 : '16px'}
            pb={isDown1000 ? 0 : '16px'}
            justifyContent={'space-between'}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: isDown1000 ? '16px' : '86px',
                mt: isDown1000 ? '0px' : '16px',
              }}
            >
              <img
                src={getReplacedCdnEndpoint(idoData.project.logoUrl)}
                style={{
                  height: '48px',
                  width: '48px',
                }}
                alt="featured launch logo"
              />
              <Typography
                fontWeight={400}
                fontSize={18}
                lineHeight={'27px'}
                fontFamily={'Inter'}
                ml={'16px'}
                color={'#000'}
              >
                {idoData.project.name}
              </Typography>
            </Box>
            {type === 'processing' ? (
              <Box>
                <StyledText>
                  We’re processing all <br /> addresses now!
                </StyledText>
                <StyledSubText>
                  This takes a few hours as we make sure the selection process
                  is careful & transparent. Check the{' '}
                  <Link
                    //href={() => navigate(`/project/${ido.project.name}`)}

                    rel="noreferrer"
                    color={'#FFB152'}
                    href={`/project/${idoData.project._id}`}
                  >
                    IDO page
                  </Link>{' '}
                  for exact timelines.
                </StyledSubText>
              </Box>
            ) : type === 'won' ? (
              <Box>
                <StyledText>
                  Congratulations, you've <br /> won a chance in the IDO.
                </StyledText>
                <StyledSubText>
                  You’re one of the winners, lock in your
                  {!isDown1000 && <br />} place by being the first to invest.
                </StyledSubText>
              </Box>
            ) : (
              <>
                {isSolanaIdo && !solanaAddress ? (
                  <Box>
                    <StyledText>Almost there</StyledText>
                    <StyledSubText>
                      Connect to your Solana wallet and sign a message to
                      complete registration.
                    </StyledSubText>
                  </Box>
                ) : (
                  <Box>
                    <StyledText>
                      Congratulations, you've <br /> registered to the IDO
                    </StyledText>
                    <StyledSubText>
                      Your current score is{' '}
                      <b
                        style={{
                          color: '#000000',
                        }}
                      >
                        {score ? Number(score).toFixed(2) : 0} points
                      </b>
                      . Increase it to {!isDown1000 && <br />} win more tickets
                      and earn higher allocations.
                    </StyledSubText>
                  </Box>
                )}
              </>
            )}
            <StatsBox
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              referralMultiplier={multiplierData}
              solanaAddress={solanaAddress}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RenderContainer
