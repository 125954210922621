import {
  Box,
  Button,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { LogoWhite } from '../../assets'
import { useState } from 'react'

import { ReactComponent as LaunchpadIcon } from '../../assets/ape_app_icon.svg'
import { ReactComponent as PortfolioIcon } from '../../assets/portfolio_icon.svg'
import { ReactComponent as ApeAppIcon } from '../../assets/launchpad_icon.svg'

import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'

import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'

// close icon
import CloseIcon from '@mui/icons-material/Close'
import BalanceAndAddressBox from '../../components/BalanceAndAddressBox'
import GiftBox from '../../components/GiftBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import WhiteButton from '../../components/WhiteButton'
import { useAppSelector } from '../../hooks'
import DisconnectWalletModal from '../../components/DisconnectWalletModal'

const CustomDrawer = ({ onItemClick }: any) => {
  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const [isShowAboutMenu, setIsShowAboutMenu] = useState<boolean>(false)
  const user = useAppSelector(state => state.user)
  const location = useLocation()
  const navigate = useNavigate()
  const [showDisconnectWalletModal, setShowDisconnectWalletModal] = useState(false)
  const isDown500 = useMediaQuery('(max-width:500px)')

  // get device height

  return (
    <>
      <Box
        sx={{
          // height: '100vh',
          height: window.screen.height,
          width: '100%',
          padding: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.72)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 5,
          }}
        >
          <img src={LogoWhite} alt="logo" />
          <IconButton onClick={onItemClick}>
            <CloseIcon
              style={{
                cursor: 'pointer',
                height: '24px',
                width: '24px',
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',

            width: '100%',
          }}
        >
          {!address ? (
            <WhiteButton
              text="Connect Wallet"
              br="40px"
              fs={'16px'}
              lh="19px"
              fw={500}
              fm="Inter"
              h="40px"
              w="70%"
              mb={2}
              onClick={(e: any) => {
                onItemClick(e)
                open()
              }}
            />
          ) : (
            <BalanceAndAddressBox
              sx={{
                borderRadius: '40px',
                backgroundColor: '#000000',
                border: '1px solid rgba(255, 255, 255, 0.2)',

                width: isDown500 ? '90%' : '70%',
                mb: 2,
              }}
            />
          )}

          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.06)',
              padding: '16px 10px 16px 10px',
              borderRadius: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              mb: 2,
            }}
          >
            {address && (
              <GiftBox
                sx={{
                  width: '100%',
                  border: '1px solid rgba(99, 234, 113, 0.2)',
                  backgroundColor: 'rgba(0, 0, 0, 1)',
                  borderRadius: '40px',
                  marginRight: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2,
                }}
              />
            )}

            {/* <ReferralButton mobile={true} callback={onItemClick} /> */}
            <RenderItem
              Icon={undefined}
              text={'About'}
              selected={false}
              onClick={(e: any) => {
                // onItemClick(e);
                setIsShowAboutMenu(current => !current)
              }}
            />
            {isShowAboutMenu && (
              <>
                <RenderItem
                  className
                  Icon={undefined}
                  text={'Web3 General'}
                  selected={false}
                  onClick={(e: any) => {
                    window.location.href = 'https://www.apeterminal.io/about/'
                  }}
                  style={{
                    maxWidth: '85%',
                  }}
                />
                <RenderItem
                  Icon={undefined}
                  text={'Web2 Institutions'}
                  selected={false}
                  onClick={(e: any) => {
                    window.location.href =
                      'https://www.apeterminal.io/about/web2-institutions'
                  }}
                  style={{
                    maxWidth: '85%',
                  }}
                />
                <RenderItem
                  Icon={undefined}
                  text={'Web2 Retail'}
                  selected={false}
                  onClick={(e: any) => {
                    window.location.href =
                      'https://www.apeterminal.io/about/web2-retail'
                  }}
                  style={{
                    maxWidth: '85%',
                  }}
                />
              </>
            )}
            <RenderItem
              Icon={LaunchpadIcon}
              text={'Launchpad'}
              selected={location.pathname === '/'}
              onClick={(e: any) => {
                onItemClick(e)
                navigate('/')
              }}
            />
            {(address || user.email || user.emailRegisterUserAccount) && (
              <RenderItem
                Icon={PortfolioIcon}
                text={'Portfolio'}
                selected={location.pathname === '/portfolio'}
                onClick={(e: any) => {
                  onItemClick(e)
                  navigate('/portfolio')
                }}
              />
            )}
            <RenderItem
              Icon={ApeAppIcon}
              text={'Transparency'}
              selected={location.pathname === '/transparency'}
              onClick={(e: any) => {
                onItemClick(e)
                navigate('/transparency')
              }}
            />
            <RenderItem
              Icon={undefined}
              text={'Ape Activator'}
              selected={false}
              onClick={(e: any) => {
                onItemClick(e)
                window.open('https://www.apeterminal.io/activator/')
                e.preventDefault()
              }}
            />
            <RenderItem
              Icon={undefined}
              text={'News'}
              selected={false}
              onClick={(e: any) => {
                onItemClick(e)
                window.open('https://www.apeterminal.io/news/')
                e.preventDefault()
              }}
              last
            />
            {/* <RenderItem
      Icon={ApeAppIcon}
      text={'Yield App'}
      selected={false}
      last
      onClick={(e: any) => {
        onItemClick(e)
        window.open('https://app.apeterminal.io', '_blank')
      }}
    /> */}
          </Box>
          {(address || user.email || user.emailRegisterUserAccount) && (
            <WhiteButton
              text="Logout"
              br="40px"
              fs={'16px'}
              lh="19px"
              fw={500}
              fm="Inter"
              h="40px"
              w="70%"
              mb={2}
              onClick={() => {
                setShowDisconnectWalletModal(true)
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              color="rgba(255, 255, 255, 1)"
              fontFamily="Inter"
              fontWeight={400}
              fontSize="16px"
              lineHeight="25px"
            >
              Join our community
            </Typography>
            <RenderSocialButtons />
          </Box>
        </Box>
      </Box>
      <DisconnectWalletModal
        onItemClick={onItemClick}
        open={showDisconnectWalletModal}
        setOpen={setShowDisconnectWalletModal}
      />
    </>
  )
}

export const RenderItem = ({
  Icon,
  text,
  selected,
  last,
  onClick,
  style,
}: any) => {
  return (
    <Button
      sx={{
        height: '48px',
        border: selected
          ? '1px solid rgba(255, 119, 34, 0.2)'
          : '1px solid rgba(255, 255, 255, 0.2)',
        width: '100%',
        borderRadius: '40px',
        backgroundColor: selected ? 'transparent' : 'rgba(0, 0, 0, 1)',
        mb: last ? 0 : 2,

        '&:hover': {
          backgroundColor: selected ? 'transparent' : 'rgba(0, 0, 0, 1)',
          opacity: 0.8,
        },
      }}
      style={style}
      onClick={onClick}
    >
      {!!Icon && (
        <Icon
          style={{
            height: '20px',
            width: '20px',
            fill: selected ? '#FF7722' : '#fff',
          }}
        />
      )}
      <Typography
        color={selected ? '#FF7722' : 'rgba(255, 255, 255, 1)'}
        fontFamily={'Inter'}
        fontWeight={500}
        fontSize={'14px'}
        lineHeight={'22px'}
        textTransform={'capitalize'}
        ml={1}
      >
        {text}
      </Typography>
    </Button>
  )
}

const RenderSocialButtons = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mt: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.06)',
        padding: '6px',
        borderRadius: '32px',
      }}
    >
      <Link
        href={'https://t.me/ApeTerminalChat'}
        target="_blank"
        rel="noopener noreferrer"
        key={'https://t.me/ApeTerminalChat'}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 1)',
          width: '100px',
          height: '40px',
          borderRadius: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: 0.5,
        }}
      >
        <TelegramNew className="orange_hover_svg" />
      </Link>

      <Link
        href={'https://twitter.com/apeterminal'}
        key={'https://twitter.com/apeterminal'}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 1)',
          width: '100px',
          height: '40px',
          borderRadius: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TwitterNew className="orange_hover_svg" />
      </Link>
    </Box>
  )
}
export default CustomDrawer
