import {
  Dialog,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { LightCross, CWIconsGoogle, GridLines } from '../../assets'
import GreenButton from '../../components/GreenButton'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import { useEffect } from 'react'
import { useSignIn } from '../../hooks/useSignIn'
import { useAppDispatch } from '../../hooks'
import { setLoginMethod } from '../../redux/slices/userSlice'

interface IProps {
  open: boolean
  onClose: () => void
}

const SignInWithGoogleModal = ({ open, onClose }: IProps) => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  const {
    handleSigInWithGoogle,
    loading,
    closeModalSignInGG,
    toastOpen,
    errorMessage,
    closeModal,
  } = useSignIn()

  const dispatch = useAppDispatch()

  useEffect(() => {
    closeModalSignInGG(onClose)
  }, [onClose])

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          dispatch(setLoginMethod(''))
          onClose()
        }}
        PaperProps={{
          sx: {
            padding: '12px',
            maxWidth: '468px',
            width: '100%',
            height: '500px',
            borderRadius: '24px',
            border: '0.5px solid rgba(255, 255, 255, 0.16)',
            backgroundColor: '#fff',
            backgroundImage:
              'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
            position: 'relative',
          },
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '40%',
            backgroundImage: `url(${GridLines})`,
            backgroundRepeat: 'repeat',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            edge="end"
            onClick={onClose}
            aria-label="close"
            sx={{
              backgroundColor: '#1D1F21',
              height: '40px',
              width: '40px',
              border: '1px solid #1D1F21',
              transition: 'all 0.2s ease',
              marginRight: '2px',
              '&:hover': {
                backgroundColor: '#0F1113',
                border: '1px solid #313335',
              },
            }}
          >
            <img src={LightCross} alt="close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '20px',
            width: isDown600 ? '90%' : '70%',
            alignSelf: 'center',
            textAlign: 'center',
            position: 'relative',
            zIndex: 3,
          }}
        >
          <img
            src={CWIconsGoogle}
            alt="Chain Change"
            style={{ width: '184px', height: '64px' }}
          />
          <Typography
            sx={{
              paddingTop: '24px',
              paddingBottom: '12px',
              fontSize: isDown600 ? '24px' : '32px',
              fontWeight: 600,
              color: '#000',
              fontFamily: 'Hellix',
            }}
          >
            Sign in With Google
          </Typography>
          <Typography
            sx={{
              color: '#7E8180',
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: 'Inter',
              marginBottom: '40px',
            }}
          >
            Signing in with your Google account is required to get the best
            experience.
          </Typography>
          <GreenButton
            text="Sign in with Google"
            br="24px"
            fs={15}
            fw={400}
            fm="Inter"
            lh="22.5px"
            p="14px 32px"
            h="48px"
            w="100%"
            sx={{ textTransform: 'none', mb: '16px' }}
            onClick={handleSigInWithGoogle}
            disabled={loading}
          />
          <OutlinedButton
            onClick={onClose}
            sx={{
              height: '48px',
              width: '100%',
              backgroundColor: '#000',
              '&:hover': { border: '1px solid #313335' },
            }}
          >
            Do it later
          </OutlinedButton>
        </Box>
      </Dialog>

      <Snackbar
        open={toastOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeModal}
      >
        <Alert
          onClose={closeModal}
          severity="error"
          sx={{
            width: '100%',
            fontSize: '14px',
            fontWeight: 600,
            color: '#FFFFFF',
            fontFamily: 'Hellix',
            textAlign: 'center',
          }}
        >
          <Typography>{errorMessage}</Typography>
        </Alert>
      </Snackbar>
    </>
  )
}

export default SignInWithGoogleModal
