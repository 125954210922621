import React, { useEffect } from 'react'
import { Typography, Box, CircularProgress, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  INotificationHistoryAPIResponse,
  INotificationHistoryResponse,
  useMarkNotificationAsReadMutation,
} from '../../services/modules/notification'
import { useAppSelector } from '../../hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationItem from './NotificationItem'
import { NoData } from "../../assets"

interface IProps {
  setShowNotifications: (value: React.SetStateAction<boolean>) => void
  showNotifications: boolean
  data: INotificationHistoryAPIResponse | undefined
  setCurrentPage: (value: React.SetStateAction<number>) => void
  refetchNotificationHistoryAuth: any
  currentPage: number
  loadingDataAuth: boolean
  loadingData: boolean
  allNotifications: INotificationHistoryResponse[]
  setAllNotifications: React.Dispatch<
    React.SetStateAction<INotificationHistoryResponse[]>
  >
}

const NotificationHeader: React.FC<IProps> = ({
  setShowNotifications,
  showNotifications,
  data,
  setCurrentPage,
  currentPage,
  refetchNotificationHistoryAuth,
  loadingDataAuth,
  loadingData,
  allNotifications,
  setAllNotifications,
}) => {
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user)
  const isLogin = Boolean(user.accessTokenGoogle || user.mainAppToken)
  const isDown768 = useMediaQuery('(max-width:768px)')
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const [expandedNotification, setExpandedNotification] = React.useState<
    string | null
  >(null)

  const handleMarkAsRead = (id: string) => {
    setAllNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notification._id === id
          ? { ...notification, isRead: true }
          : notification,
      ),
    )
    markNotificationAsRead({ id })
    refetchNotificationHistoryAuth()
  }

  const handleNavigate = (routerName: string) => {
    navigate(`/project/${routerName}`)
    setShowNotifications(false)
  }

  const handleExpandNotification = (id: string) => {
    if (expandedNotification === id) {
      setExpandedNotification(null)
    } else {
      setExpandedNotification(id)
    }
  }

  const fetchMoreData = async () => {
    if (data?.meta) {
      const { total_page } = data.meta
      if (total_page && total_page > currentPage) {
        const nextPage = currentPage + 1
        setCurrentPage(nextPage)
      }
    }
  }

  const hasMore = data?.meta.total_page && data?.meta.total_page > currentPage

  useEffect(() => {
    if (data?.data) {
      setAllNotifications(prev => {
        const uniqueNotifications = data.data.filter(
          notification =>
            !prev.some(
              prevNotification => prevNotification._id === notification._id,
            ),
        )
        return [...prev, ...uniqueNotifications]
      })
    }
  }, [data, setAllNotifications])

  if (!data?.data.length) {
    return (
      <Box
        id="notification-container"
        sx={{
          width: isDown768 ? 350 : 400,
          background: '#f2f2f2',
          padding: '12px 8px',
          minHeight: 400,
          maxHeight: 480,
          overflowY: 'auto',
          scrollBehavior: 'smooth',
          border: '1px solid rgba(0,0,0,0.05)',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#767676',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '28.8px',
            textAlign: 'left',
            color: '#000000',
            marginBottom: 2,
          }}
        >
          Notification
        </Typography>
        <Box
          sx={{
            borderTop: '1px solid #334155',
          }}
        />
        <Typography
          sx={{
            textAlign: 'center',
            marginTop: 2,
            fontSize: '14px',
            color: 'black',
          }}
        >
           <img src={NoData} alt="No Data" width={'164px'} loading="lazy" />
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      id="notification-container"
      sx={{
        width: isDown768 ? 350 : 400,
        background: '#f2f2f2',
        padding: '12px 8px',
        minHeight: 400,
        maxHeight: 480,
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        border: '1px solid rgba(0,0,0,0.05)',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#767676',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
          borderRadius: '10px',
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '28.8px',
          textAlign: 'left',
          color: '#000000',
          marginBottom: 2,
        }}
      >
        Notification
      </Typography>
      <Box
        sx={{
          borderTop: '1px solid #334155',
        }}
      />

      {(loadingData || loadingDataAuth) && currentPage === 1 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
          <CircularProgress
            sx={{
              width: '30px !important',
              height: '30px !important',
            }}
          />
        </Box>
      ) : showNotifications ? (
        <InfiniteScroll
          key={allNotifications.length}
          dataLength={data?.data?.length || 0}
          next={fetchMoreData}
          hasMore={hasMore || false}
          loader={
            <CircularProgress
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px auto',
                width: '30px !important',
                height: '30px !important',
              }}
            />
          }
          scrollThreshold={0.9}
          scrollableTarget="notification-container"
        >
          {allNotifications.map(
            (notification: INotificationHistoryResponse, index) => (
              <NotificationItem
                key={index}
                notification={notification}
                isLogin={isLogin}
                expandedNotification={expandedNotification}
                handleNavigate={handleNavigate}
                handleMarkAsRead={handleMarkAsRead}
                handleExpandNotification={handleExpandNotification}
              />
            ),
          )}
        </InfiniteScroll>
      ) : (
        <Typography
          sx={{
            textAlign: 'center',
            marginTop: 2,
            fontSize: '14px',
            color: 'black',
          }}
        >
          Notifications are hidden
        </Typography>
      )}
    </Box>
  )
  
}

export default NotificationHeader
