import { useAppDispatch, useAppSelector } from '../hooks'
import { useEffect, useState, useCallback } from 'react'
import { useAccount, useSignTypedData, useSignMessage } from 'wagmi'
import { setIsLoggedInWithEmail, setSignature } from '../redux/slices/userSlice'
import {
  useRegisterUserAccountMutation,
  useGetPendingNotificationsMutation,
  useAckNotificationsMutation,
} from '../services/modules/users'
import _ from 'lodash'
import { sendPushNotification } from '../services/notification'
import { sleep } from '../utils'
import { generateToken } from '../services/firebase'
import eventBus from '../sockets/event-bus'
import { ESocketEvent } from '../sockets/socket'

const useSignMessageAndRegisterAccount = () => {
  const { address, chain } = useAccount()
  const dispatch = useAppDispatch()
  const { signMessageAsync } = useSignMessage()
  const [signatureFetched, setSignatureFetched] = useState(false)

  const [loader, setLoader] = useState(false)

  const [userRejectedSignature, setUserRejectedSignature] = useState(false)
  const user = useAppSelector(state => state.user)

  const [registerAccount, { isLoading: isLoadingRegisteration }] =
    useRegisterUserAccountMutation()

  const [getPendingNotificationsFn] = useGetPendingNotificationsMutation()

  const [ackNotificationsFn] = useAckNotificationsMutation()

  const callRegisterAccount = useCallback(
    async (address: string, signature: string) => {
      try {
        const response = await registerAccount({
          address,
          signature,
          chainId: chain?.id || 1,
        })
        return response
      } catch (error) {
        console.error('could not register account because:', error)
        return false
      }
    },
    [chain?.id, registerAccount],
  )

  // signIn message resgister width email
  const callSignMessage = async () => {
    try {
      const signedMessage = await signMessageAsync({
        message: 'Sign in with your wallet',
      })
      dispatch(setSignature(signedMessage))
    } catch (error) {
      console.error('Error signing message:', error)
      setUserRejectedSignature(true)
      dispatch(setIsLoggedInWithEmail(false))
      return null
    }
  }

  // sign message from user with mail data

  //1. create types

  const types = {
    Mail: [
      { name: 'message', type: 'string' },
      { name: 'uri', type: 'string' },
      // { name: 'version', type: 'string' },
      // { name: 'chainId', type: 'string' },
      { name: 'contents', type: 'string' },
    ],
  } as const

  //2. create message
  const message = {
    contents: 'Sign in',
    message: 'Sign in with your wallet',
    uri: 'https://apeterminal.io/',
    // chainId: String(chain?.id || '1'),
    // version: '1.0',
  } as const

  //3. sign message function
  const {
    data: signature,
    isPending: isLoadingSignature,
    isSuccess: signatureFetchedSuccess,
    signTypedData,
    reset: resetData,
  } = useSignTypedData()

  useEffect(() => {
    if (!address) {
      resetData()
    }
  }, [address, resetData])

  useEffect(() => {
    ;(async () => {
      if (!signatureFetchedSuccess || !signature) return
      setSignatureFetched(true)
      if (signature && address) {
        const res = await callRegisterAccount(address, signature)
        const accessToken = _.get(res, 'data.addressToken.token')
        if (!accessToken) return
        // this make sure backend have some time to import the data
        await sleep(2);
        const pendingNotifications = _.get(
          await getPendingNotificationsFn(undefined),
          'data',
        )
        if (
          !_.isArray(pendingNotifications) ||
          pendingNotifications.length === 0
        )
          return
        pendingNotifications.forEach((notification: any) => {
          const { _id, title, message, link } = notification
          // sendPushNotification({
          //   id: _id,
          //   title,
          //   body: message,
          //   link: link || window.location.origin,
          // })
          // #todo: probably need some ack from service worker side
          // TODO: remove this when troll work fine
          // @ts-ignore
          // const setAppNotification = window['setAppNotification'];
          // if(typeof setAppNotification == 'function') {
          //   setAppNotification({
          //     title: 'Switch to Ape Terminal',
          //     message: String(message).replace(/\. /g, ".\n"),
          //     timeoutSecs: 60,
          //   });
          // }

          eventBus.dispatch(ESocketEvent.BROADCAST, {
            title: title || 'You Lost Money!',
            content: message,
            time: null,
            isTroll: true,
          })
        })
        ackNotificationsFn(pendingNotifications.map(n => n._id));
      }
    })()
  }, [signatureFetchedSuccess, signature, ackNotificationsFn, address, callRegisterAccount, getPendingNotificationsFn])

  const callSignTypedData = () => {
    if (user.isLoggedInWithEmail && address) {
      callSignMessage()
    }
    if (!user.isLoggedInWithEmail && address) {
      signTypedData({
        domain: {
          name: 'Ape Terminal Launchpad',
          // version: '1',
          // chainId: chain?.id,
          // verifyingContract: '0x2Fb52A9479C2eefE76D8e57A494C19826BbB74E9',
        },
        message,
        primaryType: 'Mail',
        types,
      })

      //Firebase subscribe topic
      generateToken(address);
    }
  }

  useEffect(() => {
    if (isLoadingRegisteration || isLoadingSignature) setLoader(true)
    else setLoader(false)
  }, [isLoadingRegisteration, isLoadingSignature])

  return {
    loader,
    callRegisterAccount,
    signTypedData,
    callSignTypedData,
    signatureFetched,
    userRejectedSignature,
  }
}

export default useSignMessageAndRegisterAccount
