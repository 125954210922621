import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return (
    <Box
      sx={{
        padding: isMobile ? '0 24px 0 24px' : '0 100px 0 100px',
        paddingTop: 10,
      }}
    >
      <Typography fontSize={20} mb={2} fontWeight={600}>
        Strictly private and confidential
      </Typography>
      <Typography fontSize={20} mb={2} fontWeight={600}>
        Ape Terminal App Privacy Policy
      </Typography>
      <Typography fontSize={20} mb={2}>
        APE Better Technologies Ltd, duly incorporated and registered in
        Marshall Islands with entity number 121608 and address Trust Company
        Complex, Ajeltake Road, Ajeltake Island, Majuro, Republic of the
        Marshall Islands MH 96960 (“Ape Terminal” or “We”) and its present and
        future affiliates are committed to protecting and respecting your
        privacy.
      </Typography>
      <Typography fontSize={20} mb={2}>
        This Privacy Policy outlines how Ape Terminal processes the information
        We collect about you through our App and when you otherwise interact
        with us, such as through our customer service channels. This Privacy
        Policy sets out the basis on which any personal data you as a User of
        the Ape Terminal App and Services (“User” or “You”) provide to us, will
        be used, processed or disclosed by us.
      </Typography>
      <Typography fontSize={20} mb={2}>
        By accessing or using the Ape Terminal App, its features or other
        Services (as defined in the Terms of Service for the App, accessible on
        our App), You accept this Privacy Policy and agree to comply with its
        terms. Please read the following Privacy Policy carefully to understand
        our views and practices regarding your personal data and how We will
        treat it. If You do not consent to the same, please do not access or use
        the App or the Services.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We reserve the right to amend or modify this Privacy Policy at any time
        and in response to changes in applicable law or our business practices.
        Please check frequently to see any updates or changes to our Privacy
        Policy.
      </Typography>
      <Typography fontSize={20} mb={2} fontWeight={600}>
        1. Information you give us
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. We require Users who want to participate (as purchasers) in token
        sales through the App, to give us certain contact information and
        information necessary for the provision of our Services and compliance
        with KYC/AML laws and regulations. Users are required to provide
        accurate, lawful and up-to-date information to us and to comply with all
        applicable laws when sharing personal data with us.
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. We require Users who contact us through our communication channels
        and seek customer support services, such as, for example, making
        questions, comments or other inquiries or communications to us or
        requiring support or feedback from us (“Support Services”) to give us
        certain contact and/or personal information. Users are required to
        provide accurate, lawful, and up-to-date information to us and to comply
        with all applicable laws when sharing personal data with us.
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. The personal data We collect may include:
      </Typography>
      <Typography fontSize={20} mb={1}>
        - First Name
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Middle Name
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Last Name
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Passport or Government Issued ID card number
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Email
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Physical address
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Date of Birth (DOB)
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Data about e-wallets
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Citizenship
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Information regarding sources from which funds to buy digital assets
        are obtained from e.g. name of the company, place and address of
        registration, your relationship with this company, information about
        your employment etc.
      </Typography>
      <Typography fontSize={20} mb={2}>
        - Data collected by the cookies specified below.
      </Typography>
      <Typography fontSize={20} mb={2}>
        4. When submitting a token purchase order, the following personal data
        will also be collected:
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Electronic token wallet address;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Amount sent in USDC/USDT (or other tokens, as applicable);
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Official UTC timestamp of transaction;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Transaction hash;
      </Typography>
      <Typography fontSize={20} mb={2}>
        - Date.
      </Typography>
      <Typography fontSize={20} mb={2}>
        5. The source of personal data is the User (or someone on the User’s
        behalf). In case you choose not to share certain personal data with us,
        we may not be able to provide You with our Services and Support
        Services.
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. In case you do not wish to share information stored in your device
        you may disconnect our App’s access to certain stored device information
        through your device’s settings. For instance, you can withdraw
        permission for the App to access your contact list.
      </Typography>
      <Typography fontSize={20} mb={2}>
        7. If you believe that someone has provided us with your personal
        information without your consent and you would like to request that it
        be removed from our database, please contact us at
        support@apeterminal.io.
      </Typography>
      <Typography fontSize={20} mb={2}>
        8. We will hold the above information for as long as it is necessary in
        order to provide you with the service on the App, deal with any specific
        issues that you may raise or otherwise as is required by the applicable
        laws or regulations.
      </Typography>
      <Typography fontSize={20} mb={2}>
        1. Information we collect about you automatically and Cookies.
      </Typography>
      <Typography fontSize={20} mb={2}>
        9. Our App uses cookies to distinguish you from other Users of the App.
        This helps us to provide you with a good experience when you use the App
        and also allows us to improve our App. By continuing to use the App, you
        are agreeing to our use of cookies. Where required under applicable
        laws, your consent to non-essential cookies will be requested and these
        cookies will not be placed without your consent. You can revoke your
        consent at any time.
      </Typography>
      <Typography fontSize={20} mb={2}>
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your device if you agree. Cookies contain
        information that is transferred to your device's hard drive. They are
        widely used in order to make websites and apps work, or function more
        efficiently, as well as to provide information to the owners of the
        apps.
      </Typography>
      <Typography fontSize={20} mb={2}>
        Information collected from cookies is used to evaluate the effectiveness
        of our App, analyze trends, and administer the App. The information
        collected from cookies allows us to determine which parts of our App are
        most visited and difficulties our visitors may experience in accessing
        our App. With this knowledge we can improve the quality of your
        experience on the App by recognizing and delivering the most desired
        features and information, as well as by resolving access difficulties.
      </Typography>
      <Typography fontSize={20} mb={2}>
        Most of the cookies are only used for one session and afterwards they
        are deleted from your hard drive at the end of the session. Other
        permanently stored cookies remain on your device and allow us to
        recognize you when you visit the App again.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We also use cookies and/or a technology known as web bugs or clear gifs,
        which are typically stored in emails to help us confirm your receipt of,
        and response to, our emails and to provide you with a more personalized
        experience when using our App.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We also employ adserver unique links to connect our emails, your
        responses and our offers and advertisements to each other.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We use the following cookies (with your consent where required):
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Strictly necessary cookies. These are cookies that are required for
        the operation of the Ape Terminal App. They include, for example,
        cookies that enable you to log into secure areas of the App,
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Analytical/performance cookies. They allow us to recognize and count
        the number of visitors and to see how visitors move around the App when
        they are using it. This helps us to improve the way the App works, for
        example, by ensuring that Users are finding what they are looking for
        easily.
      </Typography>
      <Typography fontSize={20} mb={2}>
        - Functionality cookies. These are used to recognize you when you return
        to the Ape Terminal App. This enables us to personalize our content for
        you, greet you by name and remember your preferences (for example, your
        choice of language or region).
      </Typography>
      <Typography fontSize={20} mb={2}>
        10. A cookie cannot read data off your hard drive or read cookie files
        created by other apps. Cookies set by the app operator are called "first
        party cookies''. Cookies set by parties other than the app operator are
        called "third party cookies". The parties that set third party cookies
        can recognize your device when it visits the App and when it visits
        certain other websites and/or mobile apps.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We may use the web analytics service (such as Google Analytics) from the
        company Google Inc. Cookies are also used with Google Analytics. This
        obtained information will be transmitted to a Google server and stored
        in the United States. If necessary, Google can transmit this data to
        third parties, as far as it is permitted by law. The same applies in the
        case of transferring data to third parties, who have legal contracts
        with Google. For more information on the privacy practices of Google,
        please visit the Google Privacy Terms web page:
        http://www.google.com/intl/en/policies/privacy/ and “How Google uses
        data when you use our partners’ sites or apps” located at
        www.google.com/policies/privacy/partners.
      </Typography>
      <Typography fontSize={20} mb={2}>
        Please note that third parties (including, for example, advertising
        networks and providers of external services like web traffic analysis
        services) may also use cookies, over which we have no control. These
        cookies are likely to be analytical/performance cookies or targeting
        cookies. Our service providers will place cookies on the hard drive of
        your device and will receive information that we select that will
        educate us on such things as how visitors navigate around our site, what
        products are browsed, and general information. Our service providers
        analyze this information and provide us with aggregate reports. The
        information and analysis provided by our service providers will be used
        to assist us in better understanding our visitors' interests in our App
        and how to better serve those interests. The information collected by
        our service providers may be linked to and combined with information
        that we collect about you while you are using the Ape Terminal website
        and/or Ape Terminal App. Our service providers is/are contractually
        restricted from using information they receive from our App other than
        to assist us.
      </Typography>
      <Typography fontSize={20} mb={2}>
        We treat information collected by cookies as non-personal information.
        However, to the extent that IP addresses or similar identifiers are
        considered personal data by applicable data protection laws, we also
        treat these identifiers as personal data. If we combine non-personal
        information with personal data, the combined information will be treated
        as personal data for as long as it remains combined.
      </Typography>
      <Typography fontSize={20} mb={2}>
        You can control the setting of cookies on your device using your
        settings. In the event the terms and conditions governing cookies are
        amended, all previous cookies managed by us will be deleted and set
        again according to the newly published terms and conditions. We cannot
        delete cookies set on our Website that are managed by other parties (for
        instance AddThis, Google, etc.).
      </Typography>
      <Typography fontSize={20} mb={2}>
        You can also block cookies by activating the setting that allows you to
        refuse the setting of all or some cookies. However, if you use your
        browser settings to block all cookies (including essential cookies) you
        may not be able to access all or parts of our site. Your use of our
        website or service with a browser that is configured to accept cookies
        constitutes an acceptance of our and third-party cookies.
      </Typography>
      <Typography fontSize={20} mb={2}>
        11. With regard to each of your visits to the App and access the
        Services we may automatically collect the following information, mostly
        throughout your activities on the App:
      </Typography>
      <Typography fontSize={20} mb={1}>
        - information about your visits, including the full Uniform Resource
        Locators (URL) clickstream to, through and from the App (including date
        and time)
      </Typography>
      <Typography fontSize={20} mb={1}>
        - Products or services you viewed or searched for; page response times,
        download errors, auction bids, length of visits to certain pages, page
        interaction information (such as scrolling, clicks, and mouse-overs),
        and methods used to browse away from the page
      </Typography>
      <Typography fontSize={20} mb={1}>
        - online identifiers and technical information, including the internet
        protocol (IP) address used to connect your computer to the internet,
        your login information, browser fingerprint, browser type and version,
        time zone setting, browser plug-in types and versions, operating system
        and platform, GPS location when applicable;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - information regarding the provision of Services we might receive from
        any other sources;
      </Typography>
      <Typography fontSize={20} mb={2}>
        - authentication data, Apple IDFA, and similar identifiers, if user
        granted permission, User ID and/ or other data collected via cookies and
        similar technologies (please read the cookie policy below for more
        information);
      </Typography>
      <Typography fontSize={20} mb={2}>
        - other information: any information we deem to require to maintain
        compliance with any law, regulation or policy and/or information
        disclosed by an individual on a voluntary basis.
      </Typography>
      <Typography fontSize={20} mb={2}>
        2. Uses made of the information
      </Typography>
      <Typography fontSize={20} mb={2}>
        12. The primary purpose in collecting personal data is to provide you
        with a secure, smooth, efficient, and customized experience. Generally,
        we use personal data to create, develop, operate, deliver, and improve
        our Services, content and advertising, as well as for loss prevention,
        anti-fraud purposes, financial compliance regulations, and consumer
        protection regulations. We use your personal data to provide a service
        to you (in contract) or to comply with our legal obligations or with
        your consent. We may also use your personal data for our legitimate
        interests or those of a third-party
      </Typography>
      <Typography fontSize={20} mb={2}>
        13. We will use your information for any other purpose only on receiving
        your consent and then, only for the purpose(s) for which grant consent
        unless we are required to do otherwise by law.
      </Typography>
      <Typography fontSize={20} mb={2}>
        14. We use information held about you in the following ways:
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to comply with applicable KYC/AML laws and regulations and our AML
        Policy;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to provide you with Services offered through the App;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to provide you with information about other services that you request
        or that we offer which are similar to those that you have already
        purchased/provided or enquired about;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - for the preparation, execution, settlement and the rescission of
        contracts and agreements relating to the services offered by Ape
        Terminal;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to notify you about changes to our Services;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to ensure that content from the App is presented in the most effective
        manner for you and for your device;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to prevent fraud and other prohibited or illegal activities;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to protect the rights and safety of our affiliated entities and third
        parties, as well as our own;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - for our legitimate interests, including comply with applicable laws,
        conducting investigations and/or responding to regulatory enquiries or
        investigations and for dispute management purposes;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - for analytical purposes;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to ensure you receive information relevant to you;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to administer the App for internal operations, including
        troubleshooting, data analysis, testing, research, statistical and
        survey purposes;
      </Typography>
      <Typography fontSize={20} mb={1}>
        - to allow you to participate in interactive features of our Services,
        when you choose to do so; and
      </Typography>
      <Typography fontSize={20} mb={1}>
        - as part of our efforts to keep the App safe and secure.
      </Typography>
      <Typography fontSize={20} mb={2}>
        3. Disclosure of your information to third parties
      </Typography>
      <Typography fontSize={20} mb={2}>
        15. We do not distribute or share any User personal information, except
        as outlined in this Privacy Policy, or as may be required by law and
        when we believe that disclosure is necessary to protect our rights or to
        comply with a judicial proceeding, court order, or legal process.
      </Typography>
      <Typography fontSize={20} mb={2}>
        16. We do not disclose your personal information to any third parties,
        except for data collection and process. Your information will be one of
        the assets transferred to the new owner in case of a merger or
        acquisition of Ape Terminal.
      </Typography>
      <Typography fontSize={20} mb={2}>
        17. You are entitled to review, correct, or amend your personal data, or
        to delete that data where it is inaccurate. You are also entitled to
        receive a copy of the personal data undergoing processing or request
        information about how your personal data is processed. Where we rely on
        legitimate interests for processing your personal data, you can object
        to this processing. Upon request, we will provide you with information
        about whether we hold or process any of your personal information,
        whether on behalf of a third party or otherwise. To request this
        information, please contact us at support@apeterminal.io.
      </Typography>
      <Typography fontSize={20} mb={2}>
        18. We will retain and use your information as necessary to comply with
        our legal obligations, resolve disputes, and enforce our agreements. We
        use a third-party platform host, who hosts the forms on our site, they
        do not have any access to the personal information that you submit on
        our App.
      </Typography>
      <Typography fontSize={20} mb={2}>
        19. We have established internal policies for the deletion of data from
        Users. Personal data whose retention period has expired or whose purpose
        of processing has been served, are deleted, destroyed, blocked or
        anonymized.
      </Typography>
      <Typography fontSize={20} mb={2}>
        20. Please note that if you do not allow us to collect or process the
        required personal information or withdraw the consent to process the
        same for the required purposes, you may not be able to access or use the
        services for which your information was sought.
      </Typography>
      <Typography fontSize={20} mb={2}>
        21. In case you do not wish to share information stored in your device
        you may disconnect our App’s access to certain stored device information
        through your device’s settings. For instance, you can withdraw
        permission for the app to access your contact list.
      </Typography>
      <Typography fontSize={20} mb={2}>
        5. Safeguarding personal data
      </Typography>
      <Typography fontSize={20} mb={2}>
        22. We will take all steps reasonably necessary to ensure that your data
        is treated securely and in accordance with this Privacy Policy.
      </Typography>
      <Typography fontSize={20} mb={2}>
        23. The data that we collect will be stored on the cloud based in the
        European Economic Area (“EEA”). Where we need to transfer your personal
        data outside the EEA to a country not otherwise deemed to have adequate
        data protection laws, we will comply with transfer restrictions such as
        executing appropriate transfer agreements and implementing required
        safeguards to protect your personal data.
      </Typography>
      <Typography fontSize={20} mb={2}>
        24. The communication internally between our servers (e.g. between
        application server and database) is handled via up-to-date best
        practices, providing an industry-standard level of protection for data
        in-transit.
      </Typography>
      <Typography fontSize={20} mb={2}>
        25. Unfortunately, the transmission of information via the internet is
        not completely secure and we cannot ensure, warrant or guarantee the
        security of your data transmitted to the App, although we will do our
        best to protect your personal data. Once we have received your
        information, we will use strict procedures and security features to try
        to prevent unauthorized access.
      </Typography>
      <Typography fontSize={20} mb={2}>
        6. Contact Information
      </Typography>
      <Typography fontSize={20} mb={2}>
        If you have questions, concerns or suggestions related to our Privacy
        Policy or our privacy practices you may contact us at:
        support@apeterminal.io.
      </Typography>
      <Typography fontSize={20} mb={2}>
        For any questions regarding your rights or if you believe that someone
        has provided us with your personal information and you would like to
        request that it be removed from our database, you can contact our data
        protection officer via this email: support@apeterminal.io.
      </Typography>{' '}
      <Typography fontSize={20} mb={2}>
        This Privacy Policy is last updated on 24 November, 2023 and is
        effective upon the date of its publication via the Ape Terminal App.
      </Typography>
    </Box>
  )
}

export default PrivacyPolicy
