import React, { useEffect, useState } from 'react'
import { getChainIdFromName } from '../../utils'
import GeneralClaim from './GeneralClaim'
import InspectClaim from './InspectClaim'
import ChainChangeModal from '../../components/ChainChangeModal'
import { useAccount } from 'wagmi'
//
const ClaimWrapper = ({ idoData, project, closeClaimTab }: any) => {
  const { chain, address } = useAccount()

  const [showChainChangeModal, setShowChainChangeModal] = useState(false)

  let chainId: number | undefined = getChainIdFromName(idoData?.claimNetwork || idoData?.project?.token?.network)
  const isInspectIdo = idoData._id === '6554d8e0bf282065fe95f222'

  const handleChainChange = () => {
    setShowChainChangeModal(true)
  }

  useEffect(() => {
    if(chainId){
      if (chain?.id !== chainId && address) {
        handleChainChange()
      } else {
        setShowChainChangeModal(false)
      }
    }
  }, [address, chain, chainId])

  return (
    <>
      {showChainChangeModal && (
        <ChainChangeModal
          open={showChainChangeModal && !!chainId && isInspectIdo}
          handleClose={() => {
            closeClaimTab()
            setShowChainChangeModal(false)
          }}
          chainId={chainId as number}
        />
      )}


      {chain?.id === chainId ? (
        isInspectIdo ? (
          <InspectClaim projectId={project._id} idoData={idoData} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {!isInspectIdo && (
        <GeneralClaim projectId={project._id} idoData={idoData} />
      )}
    </>
  )
}

export default ClaimWrapper
