import { PaletteMode } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISnackbar, ThemeState } from './theme.types'

export const initialStateSnackbar: ISnackbar = {
  message: '',
  open: false,
}

const initialState: ThemeState = {
  theme: 'dark',
  snackbar: initialStateSnackbar,
  pageLoaded: false,
  logoLoading: true,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<PaletteMode>) => {
      state.theme = action.payload
    },
    setSnackbar: (state, action: PayloadAction<ISnackbar>) => {
      state.snackbar = action.payload
    },
    setPageLoaded: (state, action: PayloadAction<boolean>) => {
      state.pageLoaded = action.payload
    },
    setLogoLoading: (state, action: PayloadAction<boolean>) => {
      state.logoLoading = action.payload
    },
  },
})

export const { setTheme, setSnackbar, setPageLoaded, setLogoLoading } =
  themeSlice.actions

export default themeSlice.reducer
