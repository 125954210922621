import React, { useEffect, useState } from 'react'
import OrangeButton from '../../components/OrangeButton'

import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import RenderLinksWithIcon from '../../components/SocialButtons'
import {
  Box,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFetchClaimMerkleProofQuery } from '../../services/modules/project'
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi'
import {
  ClaimMetrics1,
  ClaimMetrics2,
  ClaimMetrics3,
  ClaimMetrics4,
  GreenFilledArrow,
  GreenTick,
} from '../../assets'

import ClaimNewAbi from '../../contracts/claim_new.json'

import { ethers } from 'ethers'
import { useFetchSingleParticipationQuery } from '../../services/modules/participations'
import { useAppDispatch } from '../../hooks'
import { setSnackbar } from '../../redux/slices/themeSlice'
import { StyledBox } from './IDODetails'
import InfoBox from './InfoBox'
import GreenButton from '../../components/GreenButton'
import ClaimSuccessModal from '../../components/ClaimSuccessModal'
import { getPr0x1edVestingAddress } from './pr0x1edVesting'
// import { StyledTypography } from '../../pages/Overview/styles'

const InspectClaim = ({ idoData, projectId }: any) => {
  console.log('InspectClaim', idoData)
  const theme = useTheme()
  const isDown768 = useMediaQuery(theme.breakpoints.down(768))
  const [openClaimSuccessModal, setOpenClaimSuccessModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '32px',
    fontFamily: 'Inter',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  }))

  const {
    data: merkleProofData,
    isLoading: isMerkleProofLoading,
    isSuccess: isMerkleProofSuccess,
    error: merkleProofError,
    isError: isMerkleProofError,
  } = useFetchClaimMerkleProofQuery({
    projectId: idoData.project._id,
    idoNumber: 0,
  })
  const { address } = useAccount()

  const [claimed, setClaimed] = useState(false)

  const dispatch = useAppDispatch()

  const { data: foundParticipation, isLoading: singleLoading } =
    useFetchSingleParticipationQuery({
      projectId,
      idoNumber: 0,
      address: address || '',
    })

  console.log('foundParticipation', foundParticipation)

  console.log('merkleProofData', merkleProofData, merkleProofError)

  const {
    data: canClaimedData,
    isSuccess: isClaimedSuccess,
    error: isClaimedError,
    isError: isClaimedIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'canClaim',
    args: [address],
  })

  console.log(
    'canClaimedData',
    idoData._id,
    idoData?.claimContract,
    canClaimedData,
    isClaimedError,
  )

  const {
    data: getClaimableAmountData,
    isSuccess: isGetClaimableAmountSuccess,
    error: getClaimableAmountError,
    isError: getClaimableAmountIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'getClaimableAmount',
    args: [address],
  })

  console.log(
    'getClaimableAmount',
    getClaimableAmountData,
    getClaimableAmountError,
  )

  const {
    data: tokenDecimalData,
    isSuccess: isTokenDecimalSuccess,
    error: tokenDecimalError,
    isError: tokenDecimalIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'returnTokenDecimal',
  })

  const {
    data: claimedAmountData,
    isSuccess: isClaimedAmountSuccess,
    error: claimedAmountError,
    isError: claimedAmountIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'claimedAmount',
    args: [address],
  })

  console.log('allClaimDetails', {
    canClaimedData,
    isClaimedError,
    getClaimableAmountData,
    getClaimableAmountError,
    tokenDecimalData,
    tokenDecimalError,
    claimedAmountData,
    claimedAmountError,
    parsedValue: getClaimableAmountData
      ? ethers.utils.parseUnits(getClaimableAmountData.toString(), 18)
      : 0,
  })

  let parsedValue =
    getClaimableAmountData && tokenDecimalData
      ? Number(
          ethers.utils.formatUnits(
            getClaimableAmountData.toString(),
            Number(tokenDecimalData),
          ),
        )
      : 0

  let remainingTokens = 0

  // total tokens - claimedTokens
  if (idoData.totalTokens && Number(claimedAmountData)) {
    console.log('coming inside')
    remainingTokens =
      Number(idoData.totalTokens) -
      Number(
        Number(
          ethers.utils.formatUnits(
            claimedAmountData!.toString(),
            Number(tokenDecimalData),
          ),
        ).toFixed(4),
      )
  }

  const {
    data: claimData,
    isError: isErrorClaim,
    isPending: isLoadingClaim,
    isSuccess: isSuccessClaim,
    writeContractAsync: claim,
  } = useWriteContract()

  const {
    data: claimReceipt,
    isError: isErrorClaimReceipt,
    isLoading: isLoadingClaimReceipt,
    isSuccess: isSuccessClaimReceipt,
  } = useWaitForTransactionReceipt({
    hash: claimData,
    confirmations: 1,
  })
  useEffect(() => {
    if (claimReceipt) {
      console.log('claimReceipt', claimReceipt)

      dispatch(
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Claimed Successfully!',
        }),
      )

      setClaimed(true)
      setIsLoading(false)
      //onClaimed()
    }
  }, [claimReceipt])

  const onClaim = async () => {
    try {
      setIsLoading(true)

      console.log('claim running')
      claim({
        address: getPr0x1edVestingAddress(idoData?.claimContract, address),
        abi: ClaimNewAbi,
        functionName: 'claim',
      })
    } catch (error) {
      console.log('onClaim error', error)
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Hellix',
          fontSize: '24px',
          fontWeight: 600,
          color: '#FFFFFF',
          margin: '0px',
          lineHeight: '31.2px',
          paddingBottom: '24px',
        }}
      >
        Claim Details
      </Typography>
      <StyledBox>
        <InfoBox
          imageSrc={ClaimMetrics1}
          headingText="Available for Claim"
          headingVal={`${
            !canClaimedData
              ? 0
              : Number(parsedValue.toFixed(4)).toLocaleString('en')
          }`}
          spanText={idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
        />
        <InfoBox
          imageSrc={ClaimMetrics2}
          headingText="Total Tokens"
          headingVal={`${Number(idoData.totalTokens).toLocaleString('en')}`}
          spanText={idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
        />
      </StyledBox>
      <StyledBox>
        <InfoBox
          imageSrc={ClaimMetrics3}
          headingText="Claimed Tokens"
          headingVal={`${
            claimedAmountData && tokenDecimalData
              ? Number(
                  Number(
                    ethers.utils.formatUnits(
                      claimedAmountData.toString(),
                      Number(tokenDecimalData),
                    ),
                  ).toFixed(4),
                ).toLocaleString('en')
              : 0
          }`}
          spanText={idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
        />
        <InfoBox
          imageSrc={ClaimMetrics4}
          headingText="Remaining Tokens"
          headingVal={`
          ${remainingTokens.toLocaleString('en')} 
                ${idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
          `}
          hasGradient={true}
        />
      </StyledBox>

      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        IconStart={() => (
          <img
            src={GreenFilledArrow}
            style={{ marginRight: '7px' }}
            alt="forward icon"
          />
        )}
        text={
          parsedValue < 0 ? 'Claim Locked' : isLoading ? 'Loading...' : 'Claim'
        }
        disabled={claimed || parsedValue <= 0 || isLoading}
        mt={3}
        onClick={() => {
          // setOpenClaimSuccessModal(true)
          onClaim()
        }}
        outlined={true}
        w="137px"
        h="48px"
        border={'1px solid #08493C'}
        sx={{
          background: '#000000 !important',
          border: '1px solid #08493C !important',
        }}
      />
      <ClaimSuccessModal
        open={openClaimSuccessModal}
        handleClose={() => setOpenClaimSuccessModal(false)}
        title="Claimed Successfully"
        subtext="Your tokens have been claimed successfully!"
        imageSrc={GreenTick}
      />
    </Box>
  )
}

export default InspectClaim
