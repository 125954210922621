import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import {
  StepArrowDown,
  ParticipateStepOne,
  ParticipateStepTwo,
  ParticipateStepThree,
  ParticipateStepFour,
  ReferencesBg,
  CrownBlack,
  RefundableBlack,
  ReputationCheck,
} from '../../assets'
import { useNavigate } from 'react-router-dom'

interface ParticipationBoxProps {
  id: number,
  icon: string,
  title: string,
  content: string,
  highlightText?: string,
}

interface StyledBoxProps {
  boxId: number
}

const headingColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']

const ParticipationBoxContainer = styled('div')<StyledBoxProps>(
  ({ boxId, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '300px',
    height: '100%',
    // background: '#fff',
    padding: '24px',
    margin: '0.5px',
    width: `calc(100% - 1px)`,
    position: 'relative',
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow:
        boxId === 1
          ? 'inset 35px 30px 120px rgba(244, 253, 171, 0.05)'
          : boxId === 2
          ? 'inset 35px 30px 120px rgba(255, 177, 82, 0.05)'
          : boxId === 3
          ? 'inset 35px 30px 120px rgba(255, 119, 34, 0.05)'
          : 'none',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      gap: '18px',
      padding: '16px',
      alignItems: 'stretch',
      minHeight: 0,
      height: '100%',
      flexGrow: 1,
      overflow: 'hidden',
    },
  }),
)

const ParticipationBoxImage = styled('img')({
  width: '50%',
  height: '20px',
  marginLeft: 'auto',
})

const StyledValueText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '-1%',
  fontSize: '24px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '20px',
  },
}))

const StyledDescriptionText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '22.5px',
  letterSpacing: '-1%',
  color: '#7E8180',
  marginTop: '8px',
  marginBottom: '36px',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '15px',
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  background:
    'linear-gradient(142.67deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 255, 255, 0.16) 100%)',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
}))

const stepsImage = [
  ParticipateStepOne,
  ParticipateStepTwo,
  ParticipateStepThree,
  ParticipateStepFour,
]

const VerticalParticipationBox: React.FC<ParticipationBoxProps> = ({
  id,
  icon,
  title,
  content,
  highlightText,
}) => {

  return (
    <StyledBox>
      <ParticipationBoxContainer boxId={id}>
        <Box
          alignItems="center"
          height={'auto'}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          <img
            src={stepsImage[id - 1]}
            alt={`Participation Box ${id}`}
            style={{ width: '48px', height: '48px' }}
          />
          <img
            src={StepArrowDown}
            alt={`Participation Box ${id}`}
            style={{
              display: id !== 4 ? 'flex' : 'none',
              width: '20px',
              marginTop: 'auto',
            }}
          />
        </Box>

        <Box
          width={'100%'}
          height={'auto'}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box>
            <StyledValueText color={headingColors[id - 1]}>
            </StyledValueText>
            <StyledDescriptionText>{}</StyledDescriptionText>
          </Box>
        </Box>
      </ParticipationBoxContainer>
    </StyledBox>
  )
}

const ReferenceBox: React.FC<ParticipationBoxProps> = ({
  id,
  icon,
  title,
  content,
  highlightText,
}) => {

  return (
    <StyledBox>
      <ParticipationBoxContainer boxId={id}>
        <Box width={'100%'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{
              marginTop: '16px',
              paddingTop: '12px',
              // backgroundColor: 'red',
            }}
          >
            <img src={icon} alt="" />
            <Typography
              color={'#000'}
              fontSize={'24px'}
              fontWeight={650}
              lineHeight={'25.5px'}
              textAlign={'center'}
              fontFamily={'Inter'}
              marginLeft={'16px'}
            >
              {title}
            </Typography>
          </Box>
          <Box marginTop={'16px'} paddingTop={'12px'}>
            <Typography
              color={'#4b4b4b'}
              fontSize={'16px'}
              fontWeight={400}
              lineHeight={'25.5px'}
              // textAlign={'center'}
              fontFamily={'Inter'}
              paddingLeft={'56px'}
            >
              {content} {highlightText && <b>{highlightText}</b>}
            </Typography>
          </Box>
        </Box>
      </ParticipationBoxContainer>
    </StyledBox>
  )
}

const References: React.FC = () => {
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1200 = useMediaQuery('(max-width:1200px)')

  const navigate = useNavigate()

  const participationBoxes = [
    {
      id: 1,
      icon: CrownBlack,
      title: 'Buy Now, Pay Later',
      content: 'Opt into a sale. Ape Terminal pays your ticket for you! Watch the token list, if you like the performance, pay your ticket to Ape, and claim the tokens. \nIf you don’t like the performance, pay nothing.',
      highlightText: 'Commit. Don’t Pay. Win.',
    },
    {
      id: 2,
      icon: ReputationCheck,
      title: 'Open Access',
      content: 'Ape Terminal doesn’t require you to stake a launchpad token like other platforms. Join into our refundable sales with zero collateral. \nSign up in less than 60 seconds to access IDOs like AIT, where $1,000 turned to $80,000+.'
    },
    {
      id: 3,
      icon: RefundableBlack,
      title: 'Refundable',
      content: 'All the upside, none of the downside. Buy into tokens backed by leading funds. Watch the token list: if it does well, congratulations. \nIf you don’t like the performance, claim a full refund – no questions asked.'
    },
  ]

  return (
    <Grid
      container
      spacing={isDown767 ? 1 : 2}
      mb={isDown1200 ? '100px' : '24px'}
      justifyContent="center"
      
    >
      <Grid item xs={12}>
        <Typography
          fontWeight={600}
          fontSize={isDown767 ? 32 : 56}
          lineHeight={isDown767 ? '32px' : '61.5px'}
          letterSpacing={'0px'}
          textAlign={'center'}
          fontFamily={'Hellix'}
          color="#000"
          mb={'24px'}
        >
          Unparalleled Features
        </Typography>
        <Typography
          position={'relative'}
          fontWeight={400}
          fontSize={isDown767 ? 16 : 20}
          lineHeight={isDown767 ? '18px' : '32px'}
          letterSpacing={'0px'}
          textAlign={'center'}
          fontFamily={'Inter'}
          color="#767676"
          mb={'16px'}
        >
          Designed to empower your portfolio.
        </Typography>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundImage: `url(${ReferencesBg})`,
          backgroundSize: 'cover',
          // backgroundPosition: 'top',
          backgroundPositionY: '31%',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          minHeight: '453px',
          height: 'fit-content',
        }}
      >
        <Grid
          container
          spacing={isDown767 ? 1 : 2}
          justifyContent={'center'}
          sx={{
            // maxWidth: '1200px',
            width: '100%',
          }}
        >
        {participationBoxes.map((box: ParticipationBoxProps, index: number) => (
          <Grid key={box.id} item xs={12} sm={12} smd={12} md={3}>
            <ReferenceBox {...box} />
          </Grid>
        ))}
        </Grid>
      </Box>
    </Grid>
  )
}

export default References
