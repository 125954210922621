import {
  Button,
  FormControl,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useSubscribeForNewsLetterMutation } from '../../services/modules/users'

const SubscribeEmail: React.FC = () => {
  const [subscribeForNewsLetter] = useSubscribeForNewsLetterMutation()
  const [valueEmail, setValueEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    setValueEmail(email)
    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address.')
    } else {
      setError(null)
    }
  }

  const handleSubscribe = async () => {
    if (!validateEmail(valueEmail)) {
      setError('Please enter a valid email address.')
      return
    }

    try {
      const result = await subscribeForNewsLetter({
        email: valueEmail,
      }).unwrap()
      if (result) {
        setValueEmail('')
        setError(null)
        setOpenSnackbar(true)
      }
    } catch (error: any) {
      const { message } = error.data
      setError(message)
      setOpenSnackbar(false)
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <Box
        sx={{
          paddingBottom: '153px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 'max-content',
          margin: '0 auto',
          width: '100%',
          gap: '48px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
          }}
        >
          <Typography
            sx={{
              color: '#000000',
              fontFamily: 'Hellix',
              fontSize: '56px',
              fontWeight: '600',
              lineHeight: '61.6px',
              textAlign: 'center',
            }}
          >
            Subscribe to our newsletter
          </Typography>
          <Typography
            sx={{
              color: '#767676',
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '32px',
              textAlign: 'center',
            }}
          >
            Subscribe to receive our latest news potential
          </Typography>
        </Box>
        <FormControl fullWidth>
          <TextField
            value={valueEmail}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            error={!!error}
            helperText={error}
            autoComplete="off"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px !important',
                border: '1px solid #00000033',
                fontFamily: 'Inter',
                fontSize: '17px',
                fontWeight: '500',
                lineHeight: '32px',
                color: '#00000080 !important',
                outline: 'unset !important',
              },
              '& .MuiOutlinedInput-input': {
                color: '#00000080 !important',
                outline: 'unset !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                outline: 'unset !important',
              },
            }}
          />
        </FormControl>
        <Button
          onClick={handleSubscribe}
          sx={{
            padding: 'auto',
            width: '100%',
            borderRadius: '24px',
            backgroundColor: '#FF7722',
            border: '1px solid #00000033',
            transition: 'all 0.2s ease',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '289px',
            margin: '0 auto',
            pointerEvents: 'auto',
            cursor: 'pointer',
            fontSize: '15px',
            fontWeight: '500',
            color: '#000000',
            fontFamily: 'Inter',
            textTransform: 'capitalize',
            '&:hover': {
              background: '#ff7722d9',
            },
          }}
          variant="contained"
        >
          Subscribe
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          Thank you for subscribing! You’re now on our list to receive the
          latest updates!
        </Alert>
      </Snackbar>
    </>
  )
}

export default SubscribeEmail
