import { Box, Button, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  getChainCoinNameByChainId,
  shortenAddress,
  truncateExact,
} from '../../utils/'
import { ethers } from 'ethers'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import { useAccount, useBalance, usePublicClient } from 'wagmi'
import DisconnectWalletModal from '../../components/DisconnectWalletModal'

interface BalanceBoxProps {
  sx?: any
}

const BalanceBoxContainer = styled(Button)(() => ({
  height: 40,
  padding: '0 20px',
  backgroundColor: 'rgba(0, 0, 0, 1)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  borderRadius: 40,
  marginRight: 20,

  width: '120px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

const BalanceBox: React.FC<BalanceBoxProps> = ({ sx }) => {
  const { address, chain } = useAccount()

  const { data: ethBalanceData, isError: ethBalanceError } = useBalance({
    address,
  })

  return (
    <BalanceBoxContainer onClick={() => {}} sx={{ ...sx }}>
      <Typography
        color="#FFFFFF"
        fontWeight={700}
        fontSize={12}
        fontFamily={'Inter'}
        lineHeight={'24px'}
      >
        {truncateExact(parseFloat(ethBalanceData?.formatted || '0') || 0, 4)}{' '}
        {getChainCoinNameByChainId(chain?.id ? chain?.id : 56)}
      </Typography>
    </BalanceBoxContainer>
  )
}

export default BalanceBox
