import React from 'react'
import { useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { PCHeroBanner, MobileHeroBanner, CircledArrow } from '../../assets'

const HeroBanner: React.FC = () => {
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown770 = useMediaQuery('(max-width:770px)')
  const isBetween500And770 = useMediaQuery(
    '(min-width:500px) and (max-width:770px)',
  )
  const isDown500 = useMediaQuery('(max-width:500px)')

  const apeheroFlex = isBetween500And770
    ? '1 0 70%'
    : isDown770
    ? '1 0 55%'
    : '1  0  42%'

  const STATE_MACHINE_NAME = 'Interaction'
  const INPUT_NAME = 'Click'

  const { rive, RiveComponent } = useRive({
    src: 'rive/mavpa_floating.riv',
    stateMachines: STATE_MACHINE_NAME,
    artboard: 'New Artboard',
    autoplay: true,
  })

  const pressedInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    INPUT_NAME,
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: isDown770 ? 'center' : 'space-between',
        px: isDown770 ? '5%' : '8%',
        py: '32px',
        backgroundImage: isDown770
          ? `url(${MobileHeroBanner})`
          : `url(${PCHeroBanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '24px',
        height: isDown770 ? '1500px' : '400px',
        mb: '96px',
        maxHeight: isDown770
          ? window.innerHeight <= 700
            ? `${window.innerHeight}px`
            : `${window.innerHeight - 200}px`
          : '400px',
        gap: isDown770 ? '0%' : '10%',
        flexDirection: isDown770 ? 'column' : 'row',
        backgroundRepeat: 'no-repeat',
        minHeight: isDown770 ? '600px' : 'auto',
      }}
    >
      <Box
        sx={{
          flex: isDown770 ? '1 1 45%' : '1  1  50%',
          width: '100%',
          position: 'relative',
          top: isDown770 ? '32px' : '0',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: isDown770 ? '32px' : '56px',
            lineHeight: isDown770 ? '38.4px' : '61.6px',
            letterSpacing: '-1%',
            overflowWrap: 'anywhere',
            color: '#000000',
            mb: 1,
            maxWidth: isDown770 ? '100%' : '350px',
          }}
        >
          Launch on Ape Terminal
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: isDown770 ? '17px' : '18px',
            lineHeight: !isDown770 ? '27px' : '24px',
            letterSpacing: '-2%',
            overflowWrap: 'anywhere',
            color: '#000000',
            mb: isDown770 ? 3 : 4,
          }}
        >
          Don’t miss the chance and super-
          {!isDown770 && <br />}
          charge your IDO launch!
        </Typography>
        <Box width={'100%'} display={'flex'}>
          <Button
            sx={{
              backgroundColor: '#000000',
              borderRadius: '24px',
              padding: '12px 24px 12px 12px',
              alignSelf: 'center',
              border: 'none',
              width: isDown770 ? '100%' : '215px',
              '&:hover': {
                backgroundColor: '#000000',
              },
              '&:focus': {
                backgroundColor: '#000000',
              },
            }}
            variant="contained"
            onClick={() =>
              window.open(
                'https://1pgfqay9slw.typeform.com/to/Y7yqWlMH',
                '_blank',
              )
            }
          >
            <img
              src={CircledArrow}
              style={{
                marginRight: '8px',
              }}
              alt="apply-icon"
            />

            <Typography
              fontSize={15}
              fontWeight={400}
              fontFamily={'Inter'}
              lineHeight={'22.5px'}
              color="rgba(255, 255, 255, 1)"
              textTransform={'capitalize'}
              letterSpacing={'-0.01em'}
            >
              Apply for Launchpad
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          flex: apeheroFlex,
          height: isDown770 ? '356px' : '480px',
          position: 'relative',
          alignSelf: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          className="rive-animation__wrapper"
          style={{
            width: isDown770 ? '350px' : '530px',
            height: isDown770 ? '350px' : '530px',
            position: 'absolute',
            right: isDown770 ? '0' : isDown1200 ? '-180px' : '-32px',
            top: isDown500 ? `calc(50% - 140px)` : isDown770 ? '15%' : '-32px',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <RiveComponent onClick={() => pressedInput && pressedInput.fire()} />
        </div>
        {/* <img
          src={ApeHero}
          alt="Hero Banner"
          loading="lazy"
          decoding="async"
          style={{
            width: '100%',
            height: '100%',
            objectFit: isDown770 ? 'contain' : 'cover',
            objectPosition: 'center',
            position: 'absolute',
            overflow: 'visible',
            top: isDown370
              ? `calc(50% - 94px)`
              : isDown500
              ? `calc(50% - 108px)`
              : isDown770
              ? '15%'
              : '0',
          }}
        /> */}
      </Box>
    </Box>
  )
}

export default HeroBanner
