import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Web3Auth } from '@web3auth/modal'

interface AppState {
  web3AuthInstance: null | Web3Auth
  toastExpandedItem: string | null | undefined
}

const initialState: AppState = {
  web3AuthInstance: null,
  toastExpandedItem: null,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setWeb3AuthInstance: (state, action: PayloadAction<Web3Auth>) => {
      state.web3AuthInstance = action.payload
    },
    setToastExpandItem: (state, action: PayloadAction<any>) => {
      state.toastExpandedItem = action.payload
    }
  },
})

export const { setWeb3AuthInstance, setToastExpandItem } = appSlice.actions

export default appSlice.reducer
