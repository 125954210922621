import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'

type TimeBoxProps = {
  type: string
  value: number
}

const TimeBox: React.FC<TimeBoxProps> = ({ type, value }) => {
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown360 = useMediaQuery('(max-width:360px)')
  let textColor = ''

  switch (type) {
    case 'Days':
      textColor = '#F4FDAB'
      break
    case 'Hours':
      textColor = '#FFB152'
      break
    case 'Minutes':
      textColor = '#FF7722'
      break
    default:
      textColor = '#63EA71'
      break
  }

  return (
    <Box
      sx={{
        border: `1px solid rgba(255, 255, 255, 0.16)`,
        justifyContent: 'center',
        borderRadius: '12px',
        minWidth: isDown360 ? '40px' : isDown767 ? '60px' : '102px',
        minHeight: isDown767 ? '80px' : '114px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          marginBottom: '2px',
          fontSize: isDown360 ? '24px' : isDown767 ? '32px' : '44px',
          fontWeight: 600,
          fontFamily: 'Hellix',
          lineHeight: isDown360 ? '28px' : isDown767 ? '36px' : '54px',
        }}
        color={textColor}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: isDown360 ? '10px' : isDown767 ? '12px' : '14px',
          fontWeight: 400,
          fontFamily: 'Inter',
          color: 'rgba(118, 118, 118, 1)',
          lineHeight: isDown360 ? '12px' : isDown767 ? '15px' : '17px',
        }}
      >
        {type}
      </Typography>
    </Box>
  )
}

export default TimeBox
