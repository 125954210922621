import { combineReducers } from 'redux'
import wallet from '../slices/walletSlice'
import theme from '../slices/themeSlice'
import user from '../slices/userSlice'
import app from '../slices/appSlice'

import { api } from '../../services/api'

const reducers = combineReducers({
  wallet,
  theme,
  user,
  app,
  [api.reducerPath]: api.reducer,
})

export default reducers
