import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { getReplacedCdnEndpoint } from '../../utils'

const InfoBox: React.FC<{
  imageSrc: string
  headingText: string
  headingVal: string
  noBal?: boolean
}> = ({ imageSrc, headingText, headingVal, noBal }) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        width: '100%',
        overflowWrap: 'anywhere',
      }}
    >
      <Box>
        <img
          src={getReplacedCdnEndpoint(imageSrc)}
          alt="Image"
          style={{ width: '100%', maxHeight: '200px' }}
        />
      </Box>
      <Box sx={{ mt: isDown1000 ? '12px' : '24px' }}>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '15px',
            color: '#7E8180',
          }}
        >
          {headingText}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontWeight: 600,
            fontSize: isDown1000 ? '22px' : '32px',
            color: '#000',
            // background: noBal
            //   ? 'linear-gradient(180deg, #313335 0%, rgba(49, 51, 53, 0) 100%)'
            //   : 'transparent',
            // WebkitBackgroundClip: 'text',
            // WebkitTextFillColor: noBal ? 'transparent' : '#ffffff',
            // backgroundClip: 'text',
            // textFillColor: noBal ? 'transparent' : '#ffffff',
          }}
        >
          {headingVal}
        </Typography>
      </Box>
    </Box>
  )
}

export default InfoBox
