import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

interface MotionProps {
  children: React.ReactNode
  duration?: number
  threshold?: number
  from?: object
  to?: object
  delay?: number
  className?: string
  triggerOnce?: boolean
  onAnimationStart?: () => void
  style?: object
}

const Motion: React.FC<MotionProps> = ({
  children,
  duration = 0.4,
  threshold = 0.6,
  from,
  to,
  delay = 0,
  triggerOnce = true,
  onAnimationStart,
  className = '',
  style,
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    triggerOnce,
    threshold,
  })

  React.useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const initialProps = {
    opacity: 0,
    scale: 1,
    x: 0,
    y: 0,
    ...from,
  }

  const visibleProps = {
    opacity: 1,
    scale: 1,
    x: 0,
    y: 0,
    rotate: 0,
    ...to,
  }

  return (
    <motion.span
      ref={ref}
      animate={controls}
      initial="hidden"
      onAnimationStart={onAnimationStart}
      variants={{
        visible: visibleProps,
        hidden: initialProps,
      }}
      transition={{ duration, ease: 'easeOut', delay }}
      className={`${inView ? 'in-view' : ''} ${className}`}
      style={style}
    >
      {children}
    </motion.span>
  )
}

export default Motion
