import { Box, useMediaQuery } from '@mui/material'
import FeaturedLaunch from './FeaturedLaunch'
import UpcomingLaunches from './UpcomingLaunches'
import { ILaunch } from 'services/modules/project'
import SkeletonForFeaturedLaunch from './SkeletonForFeaturedLaunch'
import SkeletonForUpcomingLaunches from './SkeletonForUpcomingLaunches'
import _ from 'lodash'

interface UpcomingLaunchProps {
  allLaunchesData: ILaunch[] | undefined
}

const UpcomingLaunch = ({ allLaunchesData }: UpcomingLaunchProps) => {
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const featuredLaunch =
    _.isArray(allLaunchesData) &&
    allLaunchesData.find(launch => launch.type == 'featured')

  return (
    <Box
      style={{
        background: '#000',
        width: '100%',
        height: isDown767 ? 'max-content' : '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isDown1200 ? '0 24px' : 0,
      }}
    >
      {!allLaunchesData && (
        <>
          <SkeletonForFeaturedLaunch />
          <SkeletonForUpcomingLaunches />
        </>
      )}
      {allLaunchesData && featuredLaunch && (
        <FeaturedLaunch featuredLaunchData={featuredLaunch} />
      )}
      {allLaunchesData && allLaunchesData.length > 0 && (
        <UpcomingLaunches
          upcomingLaunchesData={allLaunchesData?.filter(
            launch =>
              launch.type === 'upcoming' ||
              launch.type === 'coming-soon' ||
              (featuredLaunch &&
                launch.type === 'featured' &&
                launch._id != featuredLaunch._id),
          )}
        />
      )}
    </Box>
  )
}

export default UpcomingLaunch
