import React from 'react'
import ChainSwitchETH from './ChainSwitchETH'
import ChainSwitchBSC from './ChainSwitchBSC'
import RenderContainer from './RenderContainer'
import RenderReceipt from './RenderReceipt'
import RenderLost from './RenderLost'
import { useAccount } from 'wagmi'
import { MAIN_CHAIN_ID } from '../../constant'

const RenderTGEPhase = ({
  userInvested,
  _userParticipation,
  user,
  idoData,
  totalInvested,
  multiplierData,
  score,
  totalParticipants,
  totalAssetsConnected,
}: any) => {
  const { chain } = useAccount()

  const renderTGEPhase = () => {
    if (
      (!idoData.investmentNetwork || idoData.investmentNetwork === 'ETH') &&
      chain?.id !== 1
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={<ChainSwitchETH />}
          type="registered"
        />
      )
    }

    if (chain?.id !== MAIN_CHAIN_ID && idoData?.investmentNetwork === 'BSC') {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={<ChainSwitchBSC />}
          type="registered"
        />
      )
    }

    if (userInvested && _userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderReceipt
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
        />
      )
    } else if (_userParticipation?.isWinner && !userInvested) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              heading="Pool is filled!"
              subText="The IDO has now ended. We’re rooting for you to invest next time, don't forget to apply to all of the upcoming IDO's!"
            />
          }
          type="won"
        />
      )
    } else if (!_userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="ended"
        />
      )
    }
  }
  return <>{renderTGEPhase()}</>
}

export default RenderTGEPhase
