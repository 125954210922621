import { SOCKET_HOST } from '../constant'
import { io, Socket } from 'socket.io-client'
import eventBus from './event-bus'
import {
  ESocketEvent,
  IDataEventBroadCast,
  IDataEventIDO,
} from './socket'

export class BaseSocket {
  private static instance: BaseSocket
  private socket?: Socket

  public token?: string
  public currentTeamId?: number

  public static getToken() {
    if (BaseSocket.instance) {
      return BaseSocket.instance?.token
    }
    return null
  }

  public static getInstance(): BaseSocket {
    if (!BaseSocket.instance) {
      BaseSocket.instance = new BaseSocket()
    }

    return BaseSocket.instance
  }

  public connect(accessToken?: string): void {
    this.socket = io(SOCKET_HOST!, {
      path: '/socket.io',
      transports: ['websocket'],
    })
    this.token = accessToken
    this.listenPublicEvent()
  }

  public reconnect(accessToken?: string): void {
    if (this.socket) {
      this.socket.disconnect()
    }
    this.connect(accessToken)
  }

  public emitContribution(walletAddress: string) {
    if (this.socket) {
      this.socket.emit(ESocketEvent.EVENT_CONTRIBUTION, { walletAddress })
    }
  }

  listenPublicEvent() {
    // @ts-ignore
    window['sendEventBus'] = (type = 'BROADCAST', data) => {
      eventBus.dispatch(type, data)
    }

    this.socket?.on(
      ESocketEvent.BROADCAST,
      async (data: IDataEventBroadCast) => {
        console.log('data BROADCAST', data)
        eventBus.dispatch(ESocketEvent.BROADCAST, data)
      },
    )
    this.socket?.on(
      ESocketEvent.EVENT_REGISTRATION,
      async (data: IDataEventIDO) => {
        console.log('data EVENT_REGISTRATION', data)
        eventBus.dispatch(ESocketEvent.EVENT_REGISTRATION, data)
      },
    )
    this.socket?.on(
      ESocketEvent.RESPONSE_EVENT_CONTRIBUTION,
      async (data: IDataEventIDO) => {
        console.log('data RESPONSE_EVENT_CONTRIBUTION', data)
        eventBus.dispatch(ESocketEvent.RESPONSE_EVENT_CONTRIBUTION, data)
      },
    )
  }
}
