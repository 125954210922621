import React, { useEffect, useState } from 'react'
import RenderContainer from './RenderContainer'
import ChainSwitchETH from './ChainSwitchETH'
import ChainSwitchBSC from './ChainSwitchBSC'
import RenderReceipt from './RenderReceipt'
import RenderLost from './RenderLost'
import { useAccount } from 'wagmi'
import { MAIN_CHAIN_ID, CHAIN_ID } from '../../constant'
import RenderSolanaAddress from './RenderSolanaAddress'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'

const RenderCompletedPhase = ({
  userInvested,
  _userParticipation,
  user,
  idoData,
  totalInvested,
  multiplierData,
  score,
  totalParticipants,
  totalAssetsConnected,
}: any) => {
  const { chain } = useAccount()
  const isSolanaIdo = idoData.claimNetwork === 'Solana'

  // if(_userParticipation) {
  //   _userParticipation = {
  //     ..._userParticipation,
  //     solanaAddress: null,
  //   }
  // }

  const [
    updateSolanaAddressInParticipation,
    {
      data: solanaAddressInParticipationData,
      isSuccess: isSolanaAddressInParticipationSuccess,
      error: solanaAddressInParticipationError,
    },
  ] = useUpdateSolanaAddressInParticipationMutation()

  const renderCompletedPhase = () => {
    if (
      (!idoData.investmentNetwork || idoData.investmentNetwork === 'ETH') &&
      chain?.id !== 1
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={<ChainSwitchETH />}
          type="registered"
        />
      )
    }

    if (chain?.id !==  MAIN_CHAIN_ID && idoData?.investmentNetwork === 'BSC') {
      // let _message = `To view investment status, switch your wallet to BSC chain`
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={<ChainSwitchBSC />}
          type="registered"
        />
      )
    }

    if (userInvested && _userParticipation?.isWinner) {
      return !isSolanaIdo || _userParticipation?.solanaAddress ? (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          solanaAddress={_userParticipation?.solanaAddress}
          sideComponent={
            <RenderReceipt
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
        />
      ) : (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderSolanaAddress
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              userParticipation={_userParticipation}
              updateSolanaAddressInParticipation={
                updateSolanaAddressInParticipation
              }
            />
          }
          type="registered"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    } else if (!userInvested && _userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              heading="Pool is filled!"
              subText="The IDO has now ended. We’re rooting for you to invest next time, don't forget to apply to all of the upcoming IDO's!"
            />
          }
          solanaAddress={_userParticipation?.solanaAddress}
          type="won"
        />
      )
    } else {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="ended"
          solanaAddress={_userParticipation?.solanaAddress}
        />
      )
    }
  }
  return <>{renderCompletedPhase()}</>
}

export default RenderCompletedPhase
