import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

export const useCode = () => {
  const queryParams = useQueryParams();
  return useMemo(() => queryParams.get('code'), [queryParams]);
};