import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ParticipationEntryPointContextType {
  participationEntryPoint: string;
  setParticipationEntryPoint: (entryPoint: string) => void;
  projectLinkSource: string;
  setProjectLinkSource: (entryPoint: string) => void;
}

const ParticipationEntryPointContext = createContext<ParticipationEntryPointContextType | undefined>(undefined);

export const useParticipationEntryPoint = (): ParticipationEntryPointContextType => {
  const context = useContext(ParticipationEntryPointContext);
  if (!context) {
    throw new Error('useParticipationEntryPoint must be used within a ParticipationEntryPointProvider');
  }
  return context;
};

interface ParticipationEntryPointProviderProps {
  children: ReactNode;
}

export const ParticipationEntryPointProvider: React.FC<ParticipationEntryPointProviderProps> = ({ children }) => {
  const [participationEntryPoint, setParticipationEntryPoint] = useState<string>('');
  const [projectLinkSource, setProjectLinkSource] = useState<string>('');

  return (
    <ParticipationEntryPointContext.Provider value={{ participationEntryPoint, setParticipationEntryPoint, projectLinkSource, setProjectLinkSource }}>
      {children}
    </ParticipationEntryPointContext.Provider>
  );
};