import { useDispatch } from 'react-redux'
import {
  setAccessTokenGoogle,
  setAddressLinkEmail,
  setAddressRegisterUserAccount,
  setEmail,
  setEmailRegisterUserAccount,
  setGoogleToken,
  setIsLoggedInWithEmail,
  setLoginMethod,
  setNameGoogleToken,
  setRefferCode,
  setSignature,
} from '../redux/slices/userSlice'

export const useRemoveGoogleData = () => {
  const dispatch = useDispatch()

  const removeDataGoogleFromRedux = () => {
    dispatch(setSignature(''))
    dispatch(setIsLoggedInWithEmail(false))
    dispatch(setEmail(''))
    dispatch(setGoogleToken(''))
    dispatch(setNameGoogleToken(''))
    dispatch(setRefferCode(''))
    dispatch(setAddressLinkEmail(''))
    dispatch(setEmailRegisterUserAccount(''))
    dispatch(setAddressRegisterUserAccount(''))
    dispatch(setLoginMethod(''))
    dispatch(setAccessTokenGoogle(''))
  }

  return { removeDataGoogleFromRedux }
}
