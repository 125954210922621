import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { CircledInfo } from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useState } from 'react'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'

import Motion from '../../components/Motion/Motion'
import { margin } from '@mui/system'
import OrangeButton from '../../components/OrangeButton'

const FAQ = ({ isBreakTitle = true }) => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  const [expanded, setExpanded] = useState<number | null>(null)

  const questions = [
    {
      id: 1,
      question: 'Do I need an account to join a sale?',
      answer:
        'No. Ape Terminal offers the smoothest participation experience. Opt into any sale without an account. Just connect your web3 wallet. We get 0 access to your assets as you never need to sign any transaction. Only if you win, you will then have to make an account.',
    },
    {
      id: 2,
      question: 'Is there a fee?',
      answer:
        'There are 0 fees to opt in. There are 0 token staking requirements. Only winners pay, and they pay only a 25% fee on their won tokens.',
    },
    {
      id: 3,
      question: 'Do I need to kyc to opt in?',
      answer:
        'While users don’t need to KYC for applying to an IDO, they must pass it to contribute if they win. If users win an allocation but fail to contribute, their allocation is backstopped by company treasury to ensure the IDO is completed.',
    },
    {
      id: 4,
      question: 'How can I increase my win chance?',
      answer:
        'Refer friends, use the Terminal’s sandwich and snipe products, or help push the Ape Terminal or project’s socials.',
    },
    {
      id: 5,
      question: 'What happens to unsold tickets?',
      answer:
        'Any unused tickets for an IDO are bought by the treasury instead.',
    },
    {
      id: 6,
      question:
        'Is my ticket for contributing to the IDO guaranteed after winning the raffle?',
      answer:
        "No, winning the raffle gives you the chance to contribute but doesn't guarantee a spot due to the limited number of contribution spots compared to the number of raffle winners. It's important to act quickly to secure your contribution.",
    },
    {
      id: 7,
      question:
        'Can the contribution to the IDO close before the published time?',
      answer:
        'Yes, the contribution window can close early if the IDO hard cap amount reaches its capacity before the scheduled end of the contribution period. Therefore, timely action is required.',
    },
  ]

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      // maxWidth={'996px'}
      paddingTop={'100px'}
      width={'100%'}
      paddingBottom={'160px'}
    >
      <Motion from={{ y: 0, filter: 'blur(5px)' }} to={{ filter: 'blur(0px)' }}>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontSize: isDown600 ? '32px' : '56px',
            fontWeight: 600,
            color: '#000',
            margin: '0px',
            lineHeight: isDown600 ? '38.4px' : '61.6px',
            textAlign: 'left',
          }}
        >
          Frequently Asked {isBreakTitle && <br />}Questions
        </Typography>
      </Motion>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isDown600 ? 'column' : 'row',
          gap: isDown600 ? '18px' : '24px',
          marginTop: '32px',
        }}
      >
        <Box>
          {questions.map((item, index) => (
            <Motion
              from={{ y: 10, filter: 'blur(5px)' }}
              to={{ filter: 'blur(0px)' }}
              delay={(0.05 * index) as number}
              style={{
                display: 'block',
                marginBottom: '8px',
              }}
              key={item.id}
            >
              <Accordion
                expanded={expanded === item.id}
                onChange={() =>
                  setExpanded(expanded === item.id ? null : item.id)
                }
                sx={{
                  boxShadow: 'unset',
                  backgroundColor: '#fff',
                  backgroundImage: 'none',
                  borderRadius: '12px',
                  border: '0.5px solid rgba(0,0,0,0.2)',
                  transition: 'all 0.3s',
                  '&.Mui-expanded': {
                    margin: '0px',
                    border: '0.5px solid #313335',
                    backgroundColor: '#000',
                  },
                  '&::before': {
                    display: 'none',
                  },
                  '&:last-of-type': {
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                  },
                  '&:first-of-type': {
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                  },
                  '&:hover': {
                    border: '0.5px solid #313335',
                    backgroundColor: '#000',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === item.id ? (
                      <RemoveOutlinedIcon
                        sx={{
                          color: '#767676',
                          height: '20px',
                          width: '20px',
                          marginLeft: '8px',
                          transition: 'color 0.3s',
                        }}
                      />
                    ) : (
                      <AddOutlinedIcon
                        sx={{
                          color: '#767676',
                          height: '20px',
                          width: '20px',
                          marginLeft: '8px',
                          transition: 'color 0.3s',
                        }}
                      />
                    )
                  }
                  sx={{
                    margin: '0px',
                    height: 'fit-content',
                    paddingX: '20px',
                    minHeight: '10px',
                    borderRadius: '12px',
                    '&.Mui-expanded': {
                      minHeight: '10px',
                    },
                    '& .MuiAccordionSummary-content': {
                      marginTop: '18px',
                      marginBottom: expanded === item.id ? '8px' : '18px',
                      minHeight: '10px',
                    },
                    '&:hover .faq__question': {
                      color: '#ffffff',
                    },
                    '&:hover .MuiAccordionSummary-expandIconWrapper svg': {
                      color: '#ffffff',
                    },
                  }}
                  className="group"
                >
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '17px',
                      fontWeight: 500,
                      color: expanded === item.id ? '#ffffff' : '#000',
                      lineHeight: '27px',
                      transition: 'color 0.3s',
                    }}
                    className="faq__question"
                  >
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#B1B4B3',
                    paddingX: '20px',
                    paddingBottom: '16px',
                    lineHeight: '24px',
                  }}
                >
                  {item.answer}
                </Typography>
              </Accordion>
            </Motion>
          ))}
        </Box>

        <Motion
          from={{ y: 10, filter: 'blur(5px)' }}
          to={{ filter: 'blur(0px)' }}
          threshold={0.2}
          style={{
            display: 'block',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              borderRadius: '12px',
              height: 'fit-content',
              border: '1px solid rgba(0,0,0,0.2)'
            }}
          >
            <img
              src={CircledInfo}
              alt="Circled Info"
              height={'32px'}
              width={'32px'}
            />
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '21px',
                color: '#000',
              }}
            >
              Can't find what you're looking for?
            </Typography>
            <OrangeButton
              fs={15}
              lh="22.5px"
              fm="Inter"
              fw={400}
              br="24px"
              text="Submit"
              onClick={() => {
                window.open('https://t.me/ApeTerminalChat', '_blank')
              }}
              w="148px"
              h="40px"
            />
          </Box>
        </Motion>
      </Box>
    </Box>
  )
}

export default FAQ
