import React, { useState } from 'react'
import {
  Box,
  Avatar,
  Typography,
  Button,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import GreenButton from '../../components/GreenButton'

const KYCnSocialBox: React.FC<{
  image: string
  heading: string
  subheading: string
  buttonText: string
  buttonAction: () => void
  status?: string
  color?: string
  kycStatus?: string
  KYCButton?: any
  helpText?: string
}> = ({
  image,
  heading,
  subheading,
  buttonAction,
  buttonText,
  KYCButton,
  color,
  status,
  helpText,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isDown1000 ? 'column' : 'row',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        width: '100%',
        overflowWrap: 'anywhere',
        gap: '12px',
        alignItems: isDown1000 ? 'flex-start' : 'center',
      }}
    >
      <Box display="flex" alignItems="start">
        <img
          src={image}
          alt={heading}
          style={{
            width: isDown1000 ? '40px' : '48px',
            height: isDown1000 ? '40px' : '48px',
          }}
        />
        <Box ml={isDown1000 ? '12px' : '16px'}>
          <Typography
            sx={{
              color: '#000',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            {heading}
          </Typography>
          <Typography
            sx={{
              color: '#7E8180',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16.8px',
            }}
          >
            {subheading}
          </Typography>
        </Box>
      </Box>
      {KYCButton ? (
        <KYCButton />
      ) : (
        <>
          {status === 'not-logged' && (
            <Button
              style={{
                borderRadius: '30px',
                padding: '8px 24px',
                border: `1px solid green`,
                backgroundColor: 'rgba(12, 3, 26, 0.08)',
              }}
            >
              <Tooltip
                title={helpText}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
              >
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  lineHeight={'22.5px'}
                  color={'#63EA71'}
                  textTransform={'capitalize'}
                  sx={{
                    cursor: 'not-allowed',
                    fontFamily: 'Inter',
                  }}
                >
                  {buttonText}
                </Typography>
              </Tooltip>
            </Button>
          )}
          {status === 'approved' && (
            <>
              <Button
                style={{
                  borderRadius: '30px',
                  padding: '8px 24px',
                  border: `1px solid green`,
                  backgroundColor: 'white!important',
                }}
              >
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  lineHeight={'22.5px'}
                  color={'#63EA71'}
                  textTransform={'capitalize'}
                  sx={{
                    cursor: 'not-allowed',
                    fontFamily: 'Inter',
                  }}
                >
                  Approved
                </Typography>
              </Button>
            </>
          )}
          {status === 'inreview' && (
            <>
              <Button
                style={{
                  borderRadius: '30px',
                  padding: '8px 24px',
                  border: `1px solid orange`,
                  backgroundColor: 'white!important',
                }}
              >
                <Typography
                  fontSize={15}
                  fontWeight={400}
                  lineHeight={'22.5px'}
                  color={'orange'}
                  textTransform={'capitalize'}
                  sx={{
                    cursor: 'not-allowed',
                    fontFamily: 'Inter',
                  }}
                >
                  In Review
                </Typography>
              </Button>
            </>
          )}
          {status !== 'approved' && status !== 'inreview' && status !== 'not-logged' && (
            <GreenButton
              fs={15}
              lh="22.5px"
              fm="Inter"
              fw={400}
              br="24px"
              text={buttonText}
              h={'40px'}
              onClick={buttonAction}
              outlined={true}
              w={'fit-content'}
              sx={{
                minWidth: '71px',
                border: '1px solid #08493C',
                padding: '3px 16px',
                color: color,
                backgroundColor:
                  buttonText === 'Approved'
                    ? 'rgba(99, 234, 113, 0.1)'
                    : 'transparent',
                '&:hover': {
                  border:
                    buttonText === 'Approved' ? 'none' : '1px solid #27B079',
                  backgroundColor:
                    buttonText === 'Approved'
                      ? 'rgba(99, 234, 113, 0.1)'
                      : 'transparent',
                },
              }}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default KYCnSocialBox
