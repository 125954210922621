import { lowercaseWordsAndPutUnderscore, sixDigitsFormatter } from '../../utils'
import { InfoGreen, NotifBellBlack, TimerDots } from '../../assets'
import GreenButton from '../../components/GreenButton'
import OrangeButton from '../../components/OrangeButton'
import {
  Box,
  Button,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ILaunch } from 'services/modules/project'
import Countdown from 'react-countdown'
import TimeBox from './TimeBox'
import { useParticipationEntryPoint } from '../../Context'
import { getReplacedCdnEndpoint } from '../../utils'
import { DEFAULTS } from '../../constant'

interface FeaturedProps {
  featuredLaunchData: ILaunch
}

const FeaturedLaunch = ({ featuredLaunchData }: FeaturedProps) => {
  const isDown1220 = useMediaQuery('(max-width:1220px)')
  const isDown1152 = useMediaQuery('(max-width:1152px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown500 = useMediaQuery('(max-width:500px)')
  const isDown360 = useMediaQuery('(max-width:360px)')
  const isDown410To360 = useMediaQuery(
    '(max-width:410px) and (min-width:360px)',
  )
  const isFairLaunch = [
    'AI Agent Drop',
    'Fair Launch',
    'Creator Drop',
  ].map(t => t.toLowerCase()).includes(String(featuredLaunchData?.saleType).toLowerCase());

  const [registrationStarted, setRegistrationStarted] = useState(
    featuredLaunchData.registrationStarted,
  )
  const { setProjectLinkSource } = useParticipationEntryPoint();
  const [comingSoon, setComingSoon] = useState(!featuredLaunchData.active)
  const [showTooltip, setShowTooltip] = useState(false)
  const isLiveLaunch = (featuredLaunchData.projectTags || []).map(tag => String(tag).toLowerCase()).includes('live');
  const isNonRefundableLaunch = (featuredLaunchData.projectTags || []).map(tag => String(tag).toLowerCase().replace(/-/g, '')).includes('non refundable');
  const isRefundableLaunch = (featuredLaunchData.projectTags || []).map(tag => String(tag).toLowerCase()).includes('refundable');

  const totalRaiseFontSize =
    featuredLaunchData.totalRaise === -2
      ? '31px'
      : featuredLaunchData.totalRaise > 999999
      ? '31px'
      : featuredLaunchData.totalRaise > 99999 &&
        featuredLaunchData.totalRaise < 1000000
      ? '44px'
      : '44px'

  const navigate = useNavigate()

  return (
    <Box
      mt={8}
      mb={4}
      // mt={isDown767 ? 0 : 6}
      width={'100%'}
      maxWidth={'1200px'}
      zIndex={2}
      sx={{
        // background:
        //   'linear-gradient(142.67deg, rgba(200, 254, 11, 0.2) 0%, rgba(255, 255, 255, 0.1) 51.04%, rgba(200, 254, 11, 0.4) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
      <Typography
        fontWeight={600}
        fontSize={isDown767 ? 32 : 56}
        lineHeight={isDown767 ? '32px' : '61.5px'}
        letterSpacing={'0px'}
        textAlign={'center'}
        fontFamily={'Hellix'}
        color="#FFFFFF"
        mb={4}
        mt={2}
      >
        Featured Project
      </Typography>
      </Box>
      <Box
        sx={{
          border: 'none',
          // background:
            // 'radial-gradient(25.58% 55.62% at 24.42% 114.58%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%), #0F1113',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
          borderRadius: '16px',
          padding: '8px',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          maxHeight: isDown1152 ? '100%' : '400px',
          height: isDown1152 ? '100%' : '400px',
          flexDirection: isDown1152 ? 'column-reverse' : 'row',
          gap: isDown1152 ? '16px' : '0px',
          width: `calc(100% - 1px)`,
          margin: '0.5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingY: isDown1152 ? '8px' : '24px',
            paddingRight: isDown1152 ? '8px' : '32px',
            paddingLeft: isDown1152 ? '8px' : '24px',
            width: isDown1152 ? '100%' : isDown1220 ? '56%' : '44%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: isDown1152 ? '8px' : '48px',
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <img
              src={getReplacedCdnEndpoint(featuredLaunchData.logoUrl)}
              style={{
                filter: comingSoon ? 'blur(10px)' : 'none',
                height: isDown500 ? '32px' : '48px',
                width: isDown500 ? '32px' : '48px',
                borderRadius: '12px',
              }}
              alt="featured launch logo"
            />
            <Typography
              fontWeight={400}
              fontSize={16}
              lineHeight={'24px'}
              fontFamily={'Inter'}
              ml={'12px'}
              color={'#000'}
            >
              {comingSoon
                ? 'Coming Very Soon'
                : featuredLaunchData.projectTitle}
            </Typography>
            </Box>
            <Box display={'flex'}>
              {
                (isRefundableLaunch) && (
                  <ChipText
                    text={"Refundable"}
                    color={'#FF7722'}
                    isDown1152={isDown1152}
                    isDown360={isDown360}
                  />
                )
              }
              {
                (isNonRefundableLaunch) && (
                  <ChipText
                    text={"Non-Refundable"}
                    color={'#FF7722'}
                    isDown1152={isDown1152}
                    isDown360={isDown360}
                  />
                )
              }
              {
                isLiveLaunch && (
                  <ChipText
                    text={"Live"}
                    color={'#FF7722'}
                    isDown1152={isDown1152}
                    isDown360={isDown360}
                  />
                )
              }
            </Box>
          </Box>
          <Tooltip title={featuredLaunchData.description}>
            <Typography
              fontWeight={400}
              fontSize={isDown1152 ? 16 : 18}
              lineHeight={isDown1152 ? '24px' : '90%'}
              fontFamily={'Inter'}
              color={'#767676'}
              pb={isDown1152 ? '5px' : '22px'}
              pt={isDown1152 ? '0' : '6px'}
              mb={isDown1152 ? '4px' : '16px'}
              width={'100%'}
              sx={{
                filter: comingSoon ? 'blur(10px)' : 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
              // title={featuredLaunchData.description}
            >
              {featuredLaunchData.description}
            </Typography>
          </Tooltip>
          {registrationStarted && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isDown360 ? 'column' : 'row',
                  justifyContent: !isDown767 ? 'space-between' : 'flex-start',
                  alignItems: 'center',
                  padding: isDown767 ? '12px' : '16px 24px',
                  gap: isDown500 ? '8px' : '24px',
                  border: '0.5px solid rgba(0, 0, 0, 0.2)',
                  borderRadius: '12px',
                  mb: isDown1152 ? '16px' : '32px',
                  minHeight: isDown1152 ? 'none' : '114px',
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'flex-start'}
                  gap={'4px'}
                  flexDirection={'column'}
                  width={'100%'}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={isDown767 ? 13 : 14}
                    lineHeight={'18.8px'}
                    color={'#7E8180'}
                    fontFamily={'Inter'}
                  >
                    {isFairLaunch ? "Initial Market Cap" : "Total Raise"}:
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Hellix',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: isDown1152 ? '22px' : totalRaiseFontSize,
                      lineHeight: isDown1152 ? '31px' : '100%',
                      letterSpacing: '-0.01em',
                      color: '#FFB152',
                    }}
                  >
                    {!registrationStarted
                      ? 'TBA'
                      : featuredLaunchData.totalRaise === -2
                      ? 'Launch Drop'
                      : `${featuredLaunchData.totalRaise.toLocaleString('en')}`}
                    {registrationStarted &&
                      featuredLaunchData.totalRaise !== -2 && (
                        <span
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: isDown1152 ? '16px' : '27px',
                            letterSpacing: '-0.02em',
                            textAlign: 'left',
                            color: '#7E8180',
                            marginLeft: '4px',
                          }}
                        >
                          USDC
                        </span>
                      )}
                  </Typography>
                </Box>

                {!isDown360 && (
                  <div style={{ width: isDown1152 ? 'auto' : 'auto' }}>
                    <Divider
                      isDown767={isDown767}
                      isDown1152={isDown1152}
                      margin={'0px !important'}
                    />
                  </div>
                )}

                <Box
                  display={'flex'}
                  alignItems={'flex-start'}
                  gap={'4px'}
                  flexDirection={'column'}
                  width={'100%'}
                  minWidth={isDown1152 ? 'none' : '153px'}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={isDown767 ? 13 : 14}
                    lineHeight={'18.8px'}
                    color={'#7E8180'}
                    fontFamily={'Inter'}
                  >
                    {isFairLaunch ? "Token Contract Reveal in:" : <>Registration {!registrationStarted ? 'Starts' : 'Ends'} in:</>}
                  </Typography>
                  {registrationStarted &&
                  featuredLaunchData.registrationEndDate ? (
                    <Countdown
                      date={featuredLaunchData.registrationEndDate}
                      renderer={({
                        days,
                        hours,
                        minutes,
                        seconds,
                        completed,
                      }) => {
                        let _timerString = ''

                        if (days > 0) {
                          _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
                        } else if (hours > 0) {
                          _timerString = `${hours}h ${minutes}m ${seconds}s`
                        } else {
                          _timerString = `${minutes}m ${seconds}s`
                        }

                        return (
                          <Typography
                            sx={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: isDown1152 ? '14px' : '18px',
                              lineHeight: '150%',
                              letterSpacing: '-0.01em',
                              borderRadius: '8px',
                              background: '#1D1F21',
                              paddingTop: '3px',
                              paddingBottom: '3px',
                              paddingLeft: '8px',
                              paddingRight: '8px',
                              color: completed
                                ? 'rgba(99, 234, 113, 1)'
                                : '#FF7722',
                              lineClamp: 1,
                              whiteSpace: isDown410To360 ? 'wrap' : 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: 'max-content',
                              marginTop: '4px',
                            }}
                          >
                            <span
                              style={{
                                background:
                                  'linear-gradient(90.22deg, rgb(255, 119, 34) 0.16%, rgb(244, 253, 171) 99.78%) text',
                                WebkitTextFillColor: 'transparent',
                              }}
                            >
                              {completed ? 'Ended' : _timerString}
                            </span>
                          </Typography>
                        )
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: 'Hellix',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: isDown1152 ? '22px' : '44px',
                        lineHeight: isDown1152 ? '31px' : '124%',
                        letterSpacing: '-0.01em',
                        color: !registrationStarted
                          ? 'rgba(99, 234, 113, 1)'
                          : '#6FEB7C',
                      }}
                    >
                      {!registrationStarted ? 'TBA' : 'Ended'}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: isDown1152 ? 'column' : 'row',
                  gap: isDown1152 ? '16px' : '24px',
                }}
              >
                <OrangeButton
                  text={
                    !registrationStarted ? 'Starting Soon' : 'Participate Now'
                  }
                  br="24px"
                  fs={15}
                  fw={400}
                  fm="Inter"
                  lh="22.5px"
                  p="14px 32px"
                  h="48px"
                  w={isDown1152 ? '100%' : 'fit-content'}
                  onClick={() => {
                      setProjectLinkSource('Featured');
                      navigate(
                        `/project/${lowercaseWordsAndPutUnderscore(
                          featuredLaunchData.routeName,
                        )}`,
                      )
                    }
                  }
                  disabled={!registrationStarted}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                    title={isFairLaunch ? "" :"We take AML/CTF seriously. KYC must be completed when winning and before participation."}
                    placement="top"
                  >
                    <img
                      src={InfoGreen}
                      style={{
                        cursor: 'pointer',
                        height: '16px',
                        width: '16px',
                      }}
                      alt="info icon"
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                  </Tooltip>
                  <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={'32px'}
                    color={'rgba(118, 118, 118, 1)'}
                    fontFamily={'Inter'}
                    ml={1}
                  >
                    {isFairLaunch ? "No KYC required" : "No KYC required to opt-in"}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {!registrationStarted &&
            featuredLaunchData.registrationStartsDate && (
              <>
                <Countdown
                  date={featuredLaunchData.registrationStartsDate}
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    let _timerString = ''

                    if (days > 0) {
                      _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
                    } else if (hours > 0) {
                      _timerString = `${hours}h ${minutes}m ${seconds}s`
                    } else {
                      _timerString = `${minutes}m ${seconds}s`
                    }

                    //call timebox component here for each day, hour, minute, second
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        <TimeBox type="Days" value={days} />
                        <img src={TimerDots} alt="timer dots" />
                        <TimeBox type="Hours" value={hours} />

                        <img src={TimerDots} alt="timer dots" />
                        <TimeBox type="Minutes" value={minutes} />

                        <img src={TimerDots} alt="timer dots" />
                        <TimeBox type="Seconds" value={seconds} />
                      </Box>
                    )
                  }}
                />
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Button
                    sx={{
                      border: '0.5px solid #313335',
                      borderRadius: '24px',
                      padding: '8px 12px 8px 12px',
                      alignSelf: 'center',
                      '&:hover': {
                        opacity: 0.8,
                        border: '0.5px solid #313335',
                      },
                      width: '200px',
                      mt: isDown767 ? '10px' : '32px',
                    }}
                    variant="outlined"
                  >
                    <img
                      src={NotifBellBlack}
                      style={{
                        marginRight: '8px',
                        height: '24px',
                        width: '24px',
                        color:'#000000'
                      }}
                      alt="notif_bell"
                    />
                    <Typography
                      fontSize={15}
                      fontWeight={400}
                      lineHeight={'22.5px'}
                      color={'#000000'}
                      textTransform={'capitalize'}
                    >
                      Get Notified
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
        </Box>

        <Box
          sx={{
            position: 'relative',
            bottom: 0,
            right: 0,
            overflow: 'hidden',
            width: isDown1152 ? '98%' : isDown1220 ? '48%' : '55%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            minHeight: isDown1152 ? '170px' : '367px',
            maxHeight: isDown1152 ? '170px' : '367px',
            alignSelf: 'center',
            mr: isDown1152 ? '0px' : '8px',
          }}
        >
          {false && isDown1152 ? (
            <img
              src={getReplacedCdnEndpoint(featuredLaunchData.bannerImageUrl || DEFAULTS.PROJECT_BANNER_URL)}
              style={{
                width: '100%',
                height: '100%',
                // filter: comingSoon ? 'blur(20px)' : 'none',
                objectFit: 'cover',
                position: 'absolute',
              }}
              alt="featured launch banner"
            />
          ) : (
            <video
              autoPlay
              loop
              playsInline
              muted
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                position: 'absolute',
              }}
              poster={getReplacedCdnEndpoint(featuredLaunchData.bannerImageUrl || DEFAULTS.PROJECT_BANNER_URL)}
            >
              <source src={getReplacedCdnEndpoint(featuredLaunchData.videoUrl)} type="video/mp4" />
            </video>
          )}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              mb: '8px',
              mr: '4px',
            }}
          >
            {featuredLaunchData.projectTags.filter(tag => !['live', 'refundable', 'non-refundable', 'non refundable'].includes(String(tag).toLowerCase())).map((tag: any, index: number) => (
              <ChipText
                key={index}
                text={tag}
                color={'#FF7722'}
                isDown1152={isDown1152}
                isDown360={isDown360}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ChipText = ({ text, color, isDown360, isDown1152 }: any) => {
  const isRefundable = String(text).toLowerCase() === 'refundable';
  const isNonRefundable = String(text).toLowerCase() === 'non refundable' || String(text).toLowerCase() === 'non-refundable';
  const isLive = String(text).toLowerCase() === 'live';
  return (
    <Box
      sx={{
        padding: isDown1152 ? '5px 10px' : '0px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isRefundable ? 'linear-gradient(90deg, rgba(238,134,65,1) 0%, rgba(240,201,128,1) 80%, rgba(244,247,178,1) 100%)' : isLive ? '#63EA71' : '#0F1113',
        borderRadius: '60px',
        height: isDown1152 ? '28px' : '32px',
        mr: '4px',
        border: isNonRefundable ? '2px solid #EE813EDD' : '0px solid #1D1F21',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: isDown360 ? '10px' : isDown1152 ? '14px' : '15px',
          lineHeight: '150%',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          letterSpacing: '-0.01em',
          color: (isLive || isRefundable) ? 'black' : (isNonRefundable ? '#EE813E' : '#B1B4B3'),
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const Divider = styled(Box)<{
  isDown767: boolean
  isDown1152: boolean
}>(({ isDown767, isDown1152 }) => ({
  width: '0.5px',
  backgroundColor: 'rgba(255, 255, 255, 0.16)',
  margin: isDown767 ? '0 12px' : '0 31px',
  height: isDown1152 ? '48px' : '64px',
}))

export default FeaturedLaunch
