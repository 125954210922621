import React from 'react'
import Rive, { Layout, Fit } from '@rive-app/react-canvas'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'

const stats = [
  {
    rive: 'rive/ape_element_01-new.riv',
    label: 'Participants',
    value: '521,459',
    color: '#000',
  },
  {
    rive: 'rive/ape_element_02-new.riv',
    label: 'Connected Assets',
    value: '$1.85B+',
    color: '#000',
  },
  {
    rive: 'rive/ape_element_03-new.riv',
    label: 'Active Arcade Players',
    value: '1,712,421',
    color: '#000',
  },
]

const stats2 = [
  {
    label: 'Amount Raised',
    value: '$7.5M+',
    color: '#FF7722',
  },
  {
    label: 'Total Value Distributed',
    value: '$65M+',
    color: '#FF7722',
  },
]

export const StyledLabelText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '-0.01em',
  color: '#7E8180',
  textAlign: 'center',
}))

export const StyledValueText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-0.01em',
  overflowWrap: 'break-word',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    overflowWrap: 'anywhere',
  },
}))

const Stats = ({ launchpadStats }: any) => {
  const isDown999 = useMediaQuery('(max-width:999px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown350 = useMediaQuery('(max-width:350px)')

  const STATE_MACHINE_NAME = 'Interaction'

  const largestValue = Math.max(
    launchpadStats?.averageROI || 0,
    launchpadStats?.totalParticipation || 0,
    launchpadStats?.totalAssetsConnected || 0,
  )

  const baseFontSize = isDown767 ? 32 : 40
  const updatedFontSize =
    largestValue > 100000000
      ? baseFontSize - 6
      : largestValue > 1000000
      ? baseFontSize - 4
      : baseFontSize
  const fontSize = isDown350 ? 22 : updatedFontSize

  return (
    <Grid
      container
      spacing={2}
      mb={'80px'}
      justifyContent={'center'}
      sx={{
        flexWrap: 'wrap',
        marginTop: isDown767 ? '70px' : '',
      }}
    >
      <Grid item xs={12}>
        <div
          className="rive-animation__wrapper"
          style={{
            display: 'grid',
            gridTemplateColumns: isDown767
              ? 'repeat(1, minmax(0, 1fr))'
              : 'repeat(3, minmax(0, 1fr))',
            gap: '14px',
          }}
        >
          {stats.map(({ rive, label, value, color }, index) => {
            let formattedValue =
              index === 0
                ? Number(launchpadStats?.averageROI)?.toFixed(2)
                : index === 1
                ? launchpadStats?.totalParticipation
                : Number(launchpadStats?.totalAssetsConnected)?.toFixed(0)

            return (
              <div
                key={index}
                className="rive-animation__item"
                style={{
                  width: '100%',
                  pointerEvents: isDown999 ? 'none' : 'auto',
                  aspectRatio: '12/5',
                  position: 'relative',
                  backgroundColor: '#fff',
                  border: `1px solid ${isDown767 ? '#767676' : 'rgba(0,0,0,0.1)'}`,
                  borderRadius: '20px',
                }}
              >
                <div
                  className="rive-animation__item-info"
                  style={{
                    position: 'absolute',
                    padding: '20px 0',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 0,
                    width: '100%',
                    textAlign: index === 1 ? 'left' : 'center',
                    pointerEvents: 'none',
                  }}
                >
                  {/* {index === 2 && <StyledLabelText>{labelTop}</StyledLabelText>} */}
                  <StyledValueText color={color} fontSize={fontSize}>
                    {''}
                    {/* {Number(formattedValue).toLocaleString('en') || 0} */}
                    {value}
                  </StyledValueText>
                  <StyledLabelText>{label}</StyledLabelText>
                </div>
              </div>
            )
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="rive-animation__wrapper"
          style={{
            display: 'grid',
            gridTemplateColumns: isDown767
              ? 'repeat(1, minmax(0, 1fr))'
              : 'repeat(3, minmax(0, 1fr))',
            gap: '14px',
          }}
        >
          {stats2.map(({ label, value, color }, index) => {
            let formattedValue =
              index === 0
                ? Number(launchpadStats?.averageROI)?.toFixed(2)
                : index === 1
                ? launchpadStats?.totalParticipation
                : Number(launchpadStats?.totalAssetsConnected)?.toFixed(0)

            return (
              <div
                key={index}
                className="rive-animation__item"
                style={{
                  width: '100%',
                  pointerEvents: isDown999 ? 'none' : 'auto',
                  aspectRatio: '12/5',
                  position: 'relative',
                  backgroundColor: '#fff',
                  border: `1px solid ${isDown767 ? '#767676' : 'rgba(0,0,0,0.1)'}`,
                  borderRadius: '20px',
                  marginLeft: isDown767 ? 0 : 115,
                }}
              >
                <div
                  className="rive-animation__item-info"
                  style={{
                    position: 'absolute',
                    padding: '20px 0',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 0,
                    width: '100%',
                    textAlign: index === 1 ? 'left' : 'center',
                    pointerEvents: 'none',
                  }}
                >
                  {/* {index === 2 && <StyledLabelText>{labelTop}</StyledLabelText>} */}
                  <StyledValueText color={color} fontSize={fontSize}>
                    {index === 2 && '$'}
                    {''}
                    {/* {Number(formattedValue).toLocaleString('en') || 0} */}
                    {value}
                  </StyledValueText>
                  <StyledLabelText>{label}</StyledLabelText>
                </div>
                {/* <Rive
                  src={rive}
                  stateMachines={STATE_MACHINE_NAME}
                  layout={new Layout({ fit: Fit.Contain })}
                /> */}
              </div>
            )
          })}
        </div>
      </Grid>
    </Grid>
  )
}

export default Stats
