import React from 'react'
import { StyledSubText, StyledText } from '.'
import { Box, Typography, useMediaQuery } from '@mui/material'
import StatsBox from './StatsBox'
import { AmberCircles } from '../../assets'
import RenderContainer from './RenderContainer'

const RenderProcessingPhase = ({
  idoData,
  score,
  totalParticipants,
  totalAssetsConnected,
  multiplierData,
}: any) => {
  return (
    <RenderContainer
      idoData={idoData}
      score={score}
      totalParticipants={totalParticipants}
      totalAssetsConnected={totalAssetsConnected}
      multiplierData={multiplierData}
      type="processing"
      sideComponent={<img src={AmberCircles} alt="Amber Circles" />}
    />
  )
}

export default RenderProcessingPhase
