import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  styled,
  Box,
  useMediaQuery,
  useTheme,
  Collapse,
} from '@mui/material'
import moment from 'moment'
import { ForwardArrow } from '../../assets'

const CircleContainer = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  background: '#0F1113',
  boxShadow:
    '0px 0.5px 0px rgba(255, 255, 255, 0.15), inset 0px 0.5px 0px rgba(255, 255, 255, 0.16)',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '16px',
}))

const NumberInsideCircle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '120%',
  letterSpacing: '-0.01em',
  color: '#FFFFFF',
}))

const getShortenedMonthName = (text: string | any) => {
  if (typeof text != 'string') return text
  return text
    .replaceAll(/January/gi, 'Jan.')
    .replaceAll(/February/gi, 'Feb.')
    .replaceAll(/September/gi, 'Sep.')
    .replaceAll(/October/gi, 'Oct.')
    .replaceAll(/November/gi, 'Nov.')
    .replaceAll(/December/gi, 'Dec.')
}

const TimeboxList = ({ data }: { data: any }) => {
  const theme = useTheme()
  const [currentTime, setCurrentTime] = useState(moment())
  const [isExpanded, setIsExpanded] = useState(false)
  const isDown1220 = useMediaQuery(theme.breakpoints.down(1220))
  const [pastRecentEventIndex, setPastRecentEventIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment())
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  const isPastEvent = (startDate: any) => startDate.isBefore(currentTime)

  const renderListItem = (item: any, index: any) => {
    const increment = 1
    const startDate = moment(item.subHeading, 'Do MMM HH:mm [UTC]')
    const isPast = isPastEvent(startDate)
    if (isPast && pastRecentEventIndex < index) {
      setPastRecentEventIndex(index)
    }
    const phases = ['Subscription', 'Snapshot', 'Lottery', 'Redemption']

    return (
      <ListItem
        key={index}
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          padding: '0px',
          gap: '12px',
        }}
      >
        <ListItemAvatar sx={{ width: '32px', minWidth: '32px' }}>
          <CircleContainer>
            <NumberInsideCircle
              sx={{
                color: isPast
                  ? '#63EA71'
                  : index === pastRecentEventIndex + increment
                  ? 'white'
                  : '#767676',
              }}
            >
              {index + increment}
            </NumberInsideCircle>
          </CircleContainer>
        </ListItemAvatar>
        <ListItemText
          primary={phases[index]}
          secondary={getShortenedMonthName(item.subHeading)}
          sx={{
            textAlign: 'left',
            '& .MuiListItemText-primary': {
              color: isPast
                ? '#63EA71'
                : index === pastRecentEventIndex + increment
                ? '#000'
                : '#767676',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: 500,
            },
            '& .MuiListItemText-secondary': {
              color: '#767676',
              textWrap: 'nowrap',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              fontSize: '12px',
              fontFamily: 'Inter',
              fontWeight: 400,
            },
          }}
        />

        <img
          src={ForwardArrow}
          alt="arrow"
          style={{
            width: '10px',
            height: '20px',
            marginInline: '24px',
            display: index === 3 ? 'none' : 'block',
          }}
        />
      </ListItem>
    )
  }

  return (
    <Box
      sx={{
        background:
          'linear-gradient(142.67deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 255, 255, 0.16) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        my: '24px',
        width: isDown1220 ? '100%' : `calc(${window.innerWidth}px - 32px)`,
        maxWidth: '1200px',
      }}
    >
      {!isDown1220 ? (
        <List
          sx={{
            display: 'flex',
            overflowX: 'auto',
            bgcolor: 'background.paper',
            paddingBottom: '24px',
            // gap: '24px',
            alignItems: 'stretch',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '64px',
            padding: '16px',
            margin: '1px',
            background: '#fff',
            border: '1px solid rgba(0,0,0,0.2)',
            borderRadius: '16px',
            width: `calc(100% - 2px)`,
          }}
        >
          {data.map(renderListItem)}
        </List>
      ) : (
        <List
          sx={{
            display: 'flex',
            overflowX: 'auto',
            bgcolor: 'background.paper',
            paddingBottom: '24px',
            gap: isExpanded ? '0px' : '24px',
            alignItems: 'stretch',
            flexDirection: isExpanded ? 'column' : 'row',
            justifyContent: 'center',
            height: isExpanded ? '232px' : '72px',
            paddingX: '16px',
            paddingY: '8px',
            margin: '1px',
            background: '#fff',
            border: '1px solid rgba(0,0,0,0.2)',
            borderRadius: '16px',
            width: `calc(100% - 2px)`,
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {renderListItem(data[0], 0)}
          <Collapse
            in={isExpanded}
            timeout={0}
            unmountOnExit
            orientation="vertical"
          >
            {data
              .slice(1, 4)
              .map((item: any, index: any) => renderListItem(item, index + 1))}
          </Collapse>
        </List>
      )}
    </Box>
  )
}

export default TimeboxList
